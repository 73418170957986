import React from 'react';
import { SearchResponse } from '../shared/shared.models';
import { Facet, JobSearchItem } from './models';
import { JobSearchDocument } from './JobSearchDocument';
import { useAppDispatch } from '../shared/store/store.hooks';
import { setInitialSearchResult } from '../shared/store/shared.reducer';

interface Props {
  searchResponse: SearchResponse<JobSearchItem>;
  dicriptionPageLink: string;
  initialQuery: string;
  defaultOrganization: string;
  workTitleFacets: Facet[];
  departmentFacets: Facet[];
  hireTypeFacets: Facet[];
  cityFacets: Facet[];
  resultPageLink: string;
  isPost: boolean;
  hasHeaderImage: boolean;
  chevronIconSvg: string;
  crossIconSvg: string;
}
export const JobSearchDocumentDataSetup: React.VFC<Props> = ({
  searchResponse,
  dicriptionPageLink,
  initialQuery,
  defaultOrganization,
  workTitleFacets,
  departmentFacets,
  hireTypeFacets,
  resultPageLink,
  cityFacets,
  isPost,
  hasHeaderImage,
  chevronIconSvg,
  crossIconSvg,
}) => {
  const dispatch = useAppDispatch();
  dispatch(setInitialSearchResult(searchResponse));

  return (
    <>
      <JobSearchDocument
        initialQuery={initialQuery}
        workTitleFacets={workTitleFacets}
        departmentFacets={departmentFacets}
        hireTypeFacets={hireTypeFacets}
        cityFacets={cityFacets}
        resultPageLink={resultPageLink}
        dicriptionPageLink={dicriptionPageLink}
        defaultOrganization={defaultOrganization}
        isPost={isPost}
        hasHeaderImage={hasHeaderImage}
        chevronIconSvg={chevronIconSvg}
        crossIconSvg={crossIconSvg}
      />
    </>
  );
};
