import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import { enablePolyfills } from '../shared/shared.polyfills';
import { store } from '../shared/store/shared.store';
import { Provider } from 'react-redux';
import { OrganisationSearchFilterObject, SelectListItem } from './models';
import { OrganisationSearchControlBlock } from './OrganisationSearchControlBlock';

interface Props {
  crossIconSvg: string;
  chevronIconSvg: string;
  resultPageLinkAsString: string;
  isPost: boolean;
  organisationSearchFilterObject: OrganisationSearchFilterObject;
  placeHolder: string;
  heading: string;
  municipalityList: SelectListItem[];
}
export const OrganisationSearchFilterApp: React.VFC<Props> = ({
  crossIconSvg,
  chevronIconSvg,
  resultPageLinkAsString,
  isPost,
  organisationSearchFilterObject,
  placeHolder,
  heading,
  municipalityList,
}) => {
  const SSR = typeof window === 'undefined';
  if (!SSR) enablePolyfills();

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    html.classList.add('vgr-searchservice__html');
  }, []);

  return (
    <Provider store={store}>
      {!SSR && (
        <BrowserRouter>
          <Routes>
            <Route
              path="*"
              element={
                <OrganisationSearchControlBlock
                  crossIconSvg={crossIconSvg}
                  chevronIconSvg={chevronIconSvg}
                  resultPageLinkAsString={resultPageLinkAsString}
                  isPost={isPost}
                  organisationSearchFilterObject={organisationSearchFilterObject}
                  municipalityList={municipalityList}
                  placeHolder={placeHolder}
                  heading={heading}
                />
              }></Route>
          </Routes>
        </BrowserRouter>
      )}
      {SSR && (
        <StaticRouter location="/">
          <Routes>
            <Route
              path="*"
              element={
                <OrganisationSearchControlBlock
                  crossIconSvg={crossIconSvg}
                  chevronIconSvg={chevronIconSvg}
                  resultPageLinkAsString={resultPageLinkAsString}
                  isPost={isPost}
                  organisationSearchFilterObject={organisationSearchFilterObject}
                  municipalityList={municipalityList}
                  placeHolder={placeHolder}
                  heading={heading}
                />
              }></Route>
          </Routes>
        </StaticRouter>
      )}
    </Provider>
  );
};
