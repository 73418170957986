import React, { useState } from "react";

import Select from "react-select";
import {
  ActionMeta,
  GroupBase,
  OptionsOrGroups,
} from "react-select/dist/declarations/src/types";
import {
  AriaGuidanceProps,
  AriaOnChangeProps,
  AriaOnFocusProps,
  AriaOnFilterProps,
} from "react-select/dist/declarations/src/accessibility";
import { Facet } from "../models";
import { useSearchEngine } from "../../shared/hooks";
import { swedishAriaLiveMessages } from "../../shared/swedishAriaLiveMessages";

interface Props {
  itemsId: string;
  itemsName: string;
  facets: Facet[];
  isPost: boolean;
  isLoading: boolean;
}
export const MultiSelect: React.VFC<Props> = ({
  itemsId,
  itemsName,
  facets,
  isPost,
  isLoading,
}) => {
  type Option = { label: string; value: string };

  /*********************************
   * Variables
   *********************************/
  const [selectedValuesLandingPage, setSelectedValuesLandingPage] = useState(
    []
  );

  const selectedValues =
    facets.length > 0
      ? (facets
          .filter((x) => x.selected)
          .map((facet) => ({
            value: facet.query,
            label: facet.displayName,
          })) as Option[])
      : [];

  const selectOptions =
    facets.length > 0
      ? (facets
          .filter((x) => !x.selected)
          .map((facet) => ({
            value: facet.query,
            label: facet.displayName + " (" + facet.count + ")",
          })) as Option[])
      : [];

  const [doSearch] = useSearchEngine("Search/FilterJobSearchDocument");

  const selectOnChange = (
    option: readonly Option[],
    actionMeta: ActionMeta<Option>
  ) => {
    if (isPost) {
      let query = "";
      if (actionMeta.action === "clear") {
        // This Regex will remove all municiplity facets and keep all other facets, sort order and query
        const removeMunicipalityFacetRegex = new RegExp(
          `&?${itemsName}=[^&]*`,
          "g"
        );
        // We use the first object in the array bacause it doesn't matter which one we use.
        // They will all contain the same query when we clear the municipality facet...
        query = actionMeta.removedValues[0].value.replace(
          removeMunicipalityFacetRegex,
          ""
        );
      } else {
        // Get the new applied query
        query =
          actionMeta.action === "remove-value"
            ? actionMeta.removedValue.value
            : actionMeta.option.value;
      }

      doSearch(query + "&isPost=true");
    } else {
      const options =
        option.length > 0
          ? (option.map((facet) => ({
              value: facet.value,
              label: facet.label,
            })) as Option[])
          : [];

      setSelectedValuesLandingPage(options);
    }
  };

  /*********************************
   * Render
   *********************************/
  return (
    <Select
      isMulti={true}
      inputId={itemsId}
      closeMenuOnSelect={false}
      name={itemsName}
      options={selectOptions}
      onChange={selectOnChange}
      placeholder={"Kommun (Alla)"}
      value={isPost ? selectedValues : selectedValuesLandingPage}
      isDisabled={isLoading}
      ariaLiveMessages={swedishAriaLiveMessages}
      className="multi-select"
      classNames={{
        control: (state) =>
          state.isFocused
            ? "multi-select__control multi-select__control--focus"
            : "multi-select__control",
        menuList: (state) => "multi-select__menu-list",
        option: (state) => "multi-select__option",
        indicatorsContainer: (state) => "multi-select__indicators-container",
        multiValue: (state) => "multi-select__multi-value",
        multiValueLabel: (state) => "multi-select__multi-value-label",
        multiValueRemove: (state) =>
          state.isFocused
            ? "multi-select__multi-value-remove multi-select__multi-value-remove--focus"
            : "multi-select__multi-value-remove",
        clearIndicator: (state) => "multi-select__clear-indicator",
        placeholder: (state) => "multi-select__placeholder",
      }}
    />
  );
};
