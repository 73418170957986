import React, { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ApiModel, MunicipalityFilter, VaccinationsPageProps } from './types';
import { VaccinationRecipiency } from './VaccinationRecipiency/VaccinationRecipiency';

export const VaccinationsPage: React.VFC<VaccinationsPageProps> = ({ apiResponse, apiUrl, municipalitiesAndDistrictsResponse, pageUrl, municipality }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [currentVaccinationRecipiencies, setCurrentVaccinationRecipiencies] = useState<ApiModel>(apiResponse);
    const [municipalities] = useState<MunicipalityFilter[]>(municipalitiesAndDistrictsResponse);
    const [selectedMunicipality, setSelectedMunicipality] = useState<MunicipalityFilter>(municipalitiesAndDistrictsResponse.filter(x => x.code == municipality)[0]);
    const [dropinChecked, setDropinChecked] = useState<boolean>(searchParams && searchParams.get('hasDropin') !== null ? true : false);
    const [minimumTimeslotsChecked, setMinimumTimeslotsChecked] = useState<boolean>(searchParams && searchParams.get('minimumTimeslots') !== null ? true : false);
    const [selectedSort, setSelectedSort] = useState<boolean>(null);
    const [showDistanceSort, setShowDistanceSort] = useState<boolean>(false);
    const [selectedDateTimeInterval, setSelectedDateTimeInterval] = useState<string>();
    const [position, setPosition] = useState<GeolocationPosition>();
    const sortDistance = useRef<HTMLButtonElement>();
    const sortDistanceOption = useRef<HTMLOptionElement>();
    const selectDateTimeInterval = useRef<HTMLSelectElement>();

    const resultClassList = isLoading ? "loading list-component__ul" : "list-component__ul";
    const delimiter = ' | ';

    async function onChangeFilter(event: React.ChangeEvent<HTMLSelectElement>) {
        let response = "";
        let queryParam = "";

        if (parseInt(event.target.value) == 0 && event.target.id === 'date-filter') {
            searchParams.delete('toDate');
            setSearchParams(searchParams);
            setSelectedDateTimeInterval('alla-kommande-tider');

            response = await Get(window.location.search);

        } else if (parseInt(event.target.value) == 0 && event.target.id == 'kommun-filter') {
            const municipality = municipalities.filter(x => x.code == event.target.value)[0];
            searchParams.delete('municipalityCode');
            searchParams.delete('districtName');
            setSearchParams(searchParams);
            setSelectedMunicipality(municipality);

            response = await Get(window.location.search);
        } else {
            if (event.target.id === 'kommun-filter') {
                const municipality = municipalities.filter(x => x.code == event.target.value)[0];
                if (municipality.districts.length === 0) {
                    searchParams.delete('districtName');
                }
                queryParam = 'municipalityCode';
                searchParams.set(queryParam, event.target.value);
                setSearchParams(searchParams);
                setSelectedMunicipality(municipality);
            }
            if (event.target.id === 'område-filter' && parseInt(event.target.value) == 0) {
                searchParams.delete('districtName');
                setSearchParams(searchParams);
            } else if (event.target.id === 'område-filter') {
                queryParam = 'districtName';
                searchParams.set(queryParam, event.target.value);
                setSearchParams(searchParams);
            }
            if (event.target.id === 'date-filter') {
                queryParam = 'toDate';
                searchParams.set(queryParam, event.target.value);
                setSearchParams(searchParams);
                setSelectedDateTimeInterval(event.target.options[event.target.options.selectedIndex].id);
            }

            response = await Get(window.location.search);
        }

        const model = JSON.parse(response) as ApiModel;

        model.testcenters = model.testcenters.filter(x => x.timeslots != 0 || x.dropin);

        getPosition(position, model);
    }

    async function onChangeCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
        setIsLoading(true);
        let response = "";
        let queryParam = "";

        if (event.target.checked) {
            if (event.target.id === "hasDropin") {
                setDropinChecked(!dropinChecked);
                queryParam = 'hasDropin';
                searchParams.set(queryParam, event.target.checked.toString());
                setSearchParams(searchParams);
            } else {
                setMinimumTimeslotsChecked(!minimumTimeslotsChecked);
                queryParam = 'minimumTimeslots';
                searchParams.set(queryParam, '1');
                setSearchParams(searchParams);
            }
        } else {
            event.target.id === "hasDropin" && setDropinChecked(!dropinChecked);
            event.target.id === "minimumTimeslots" && setMinimumTimeslotsChecked(!minimumTimeslotsChecked);
            searchParams.delete(event.target.id);
            setSearchParams(searchParams);
        }

        response = await fetch(apiUrl + window.location.search)
            .then(data => data.text());

        const model = JSON.parse(response) as ApiModel;

        model.testcenters = model.testcenters.filter(x => x.timeslots != 0 || x.dropin);

        getPosition(position, model);

        setIsLoading(false);
    }

    async function Get(queryParams: string) {
        setIsLoading(true);

        const response = await fetch(apiUrl + queryParams)
            .then(data => data.text());

        setIsLoading(false);
        return response;
    }

    function onClickSort(event: React.MouseEvent<HTMLButtonElement>) {
        setSelectedSort(!selectedSort);
        if (event.currentTarget.id == "sort-distance" && !event.currentTarget.disabled)
            currentVaccinationRecipiencies.testcenters.sort(function (a, b) {
                if (a.distance === null) {
                    return 1;
                }
                if (b.distance === null) {
                    return -1;
                }

                return a.distance - b.distance;
            });
        else
            currentVaccinationRecipiencies.testcenters.sort((a, b) => a.title.localeCompare(b.title, 'sv'));
    }

    function onChangeSort(event: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedSort(!selectedSort);
        if (event.currentTarget.selectedOptions[0].id == "sort-distance-mobile")
            currentVaccinationRecipiencies.testcenters.sort(function (a, b) {
                if (a.distance === null) {
                    return 1;
                }
                if (b.distance === null) {
                    return -1;
                }

                return a.distance - b.distance;
            });
        else
            currentVaccinationRecipiencies.testcenters.sort((a, b) => a.title.localeCompare(b.title, 'sv'));
    }

    const getPosition = (data: GeolocationPosition, response: ApiModel) => {
        if (data !== null && data !== undefined) {
            setPosition(data);
            response.testcenters.map((recipiency) => {
                if (recipiency.latitude !== null && recipiency.longitude !== null) {
                    recipiency.distance = getDistanceFromLatLonInKm(recipiency.latitude, recipiency.longitude, data.coords.latitude, data.coords.longitude);
                } else {
                    recipiency.distance = null;
                }
            })

            if (selectedSort && selectedSort !== null)
                response.testcenters.sort(function (a, b) {
                    if (a.distance === null) {
                        return 1;
                    }
                    if (b.distance === null) {
                        return -1;
                    }

                    return a.distance - b.distance;
                });
            else if (!selectedSort)
                response.testcenters.sort((a, b) => a.title.localeCompare(b.title, 'sv'));

            setShowDistanceSort(true);
        }
        setCurrentVaccinationRecipiencies(response);

        function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
            const R = 6371; // Radius of the earth in km
            const dLat = deg2rad(lat2 - lat1);  // deg2rad below
            const dLon = deg2rad(lon2 - lon1);
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2)
                ;
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const d = R * c; // Distance in km
            return d;
        }
    };

    function deg2rad(deg: number) {
        return deg * (Math.PI / 180)
    }

    const onError = (error: GeolocationPositionError) => {
        if (error.PERMISSION_DENIED) {
            sortDistanceOption.current.disabled = true;
            setShowDistanceSort(false);
        }
    };

    function calculateDateFilters(numberOfHours: number): string {
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + numberOfHours);
        currentDate.setTime(currentDate.getTime() - new Date().getTimezoneOffset() * 60 * 1000);

        return currentDate.toISOString().split('.')[0];
    }

    async function clearAllFilters(): Promise<void> {
        setSelectedMunicipality({ code: "0", districts: [], name: "" });
        setDropinChecked(false);
        setMinimumTimeslotsChecked(false);
        setSelectedDateTimeInterval("alla-kommande-tider");

        selectDateTimeInterval.current.selectedIndex = 0;

        setSearchParams("");
        const response = await Get("");

        const model = JSON.parse(response) as ApiModel;

        model.testcenters = model.testcenters.filter(x => x.timeslots != 0 || x.dropin);

        getPosition(position, model);

        setIsLoading(false);
    }

    !position && typeof window !== "undefined" && navigator.geolocation.getCurrentPosition(data => getPosition(data, apiResponse), error => onError(error));

    return (
        <form method='get' action={pageUrl} className='vgr-itemlist'>
            <div className='vgr-itemlist__filter-wrapper'>
                {currentVaccinationRecipiencies &&
                    <>
                        <div className='vgr-itemlist__dropdown-filter'>
                            <label htmlFor="kommun-filter">Kommun</label>
                            <select autoComplete='off' id="kommun-filter" value={selectedMunicipality && selectedMunicipality.code} onChange={onChangeFilter} className="vgr-itemlist_filter" name="municipalityCode" aria-label="Filtrera på kommun" aria-controls="mottagning-count">
                                <option id="alla-kommuner" value={0}>Alla kommuner</option>
                                {municipalities.map((municipality) => {
                                    return (
                                        municipality.districts.length != 0
                                            ? <option className='has-children' key={municipality.code} value={municipality.code}>{municipality.name}</option>
                                            : <option key={municipality.code} value={municipality.code}>{municipality.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {selectedMunicipality && selectedMunicipality.districts.length != 0 &&
                            <div className='vgr-itemlist__dropdown-filter'>
                                <label htmlFor="område-filter">Område</label>
                                <select autoComplete='off' id="område-filter" value={searchParams && searchParams.get('districtName') !== null ? searchParams.get('districtName') : ""} onChange={onChangeFilter} className="vgr-itemlist_filter" name="districtName" aria-label="Filtrera på område" aria-controls="mottagning-count">
                                    <option id="alla-områden" value={0}>Alla områden</option>
                                    {municipalities.map((municipality) => {
                                        return (
                                            municipality.districts.map((district) => {
                                                return (
                                                    <option key={district.name} value={district.name}>{district.name}</option>
                                                )
                                            })
                                        )
                                    })}
                                </select>
                            </div>
                        }
                        <div className='vgr-listitem__filter-wrapper vgr-listitem__checkbox-filter'>
                            <fieldset className='vgr-listitem__checkbox-heading'>
                                <legend><b>Visa mottagningar som har</b></legend>
                                <div className='vgr-listitem__checkbox-filter'>
                                    <div>
                                        <input id='hasDropin' type="checkbox" onChange={onChangeCheckbox} checked={dropinChecked}></input>
                                        <label htmlFor="hasDropin">Drop-in</label>
                                    </div>
                                    <div>
                                        <input id='minimumTimeslots' type="checkbox" onChange={onChangeCheckbox} checked={minimumTimeslotsChecked}></input>
                                        <label htmlFor="minimumTimeslots">Bokningsbara tider</label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div id="timeinterval" className='vgr-itemlist__dropdown-filter'>
                            <label htmlFor="date-filter">Tidsperiod för bokningsbara tider</label>
                            <select autoComplete='off' id="date-filter" defaultValue={0} onChange={onChangeFilter} ref={selectDateTimeInterval} className="vgr-itemlist_filter" name="toDate" aria-label="Filtrera på tidsperiod för bokningsbara tider" aria-controls="mottagning-count">
                                <option id="alla-kommande-tider" value={0}>Alla kommande tider</option>
                                <option id="24 timmar" value={calculateDateFilters(24)}>Inom 24 timmar</option>
                                <option id="48 timmar" value={calculateDateFilters(48)}>Inom 48 timmar</option>
                                <option id="1 vecka" value={calculateDateFilters(168)}>Inom 1 vecka</option>
                                <option id="2 veckor" value={calculateDateFilters(336)}>Inom 2 veckor</option>
                                <option id="4 veckor" value={calculateDateFilters(672)}>Inom 4 veckor</option>
                                <option id="8 veckor" value={calculateDateFilters(1344)}>Inom 8 veckor</option>
                            </select>
                        </div>
                        <button className="link-focus" onClick={clearAllFilters} type="reset" disabled={isLoading}>
                            Rensa filter
                        </button>
                    </>
                }
            </div>
            <b id='mottagning-count' aria-live='polite'>Antal mottagningar: {currentVaccinationRecipiencies && currentVaccinationRecipiencies.testcenters.length}</b>
            <div className="vgr-itemlist__resultsorting">
                <div className='desktop'>
                    <span id="sort-label">Sortera efter: </span>
                    {showDistanceSort ?
                        <button ref={sortDistance} id="sort-distance" disabled={selectedSort || isLoading} aria-label="Sortera efter Avstånd" type="button" className="vgr-searchservice__linkbutton" onClick={onClickSort} aria-controls="mottagning-count">Avstånd</button> :
                        <button ref={sortDistance} id="sort-distance" disabled={true} aria-label="Sortera efter Avstånd" type="button" className="vgr-searchservice__linkbutton disabled" onClick={onClickSort} aria-controls="mottagning-count">Avstånd</button>
                    }
                    {delimiter}
                    <button id="sort-alphabetical" disabled={!selectedSort || isLoading} aria-label="Sortera efter A-Ö" type="button" className="vgr-searchservice__linkbutton" onClick={onClickSort} aria-controls="mottagning-count">A-Ö</button>
                </div>
                <div className='mobile'>
                    <select
                        onChange={onChangeSort}
                        disabled={isLoading}
                        className="vgr-itemlist_filter"
                        aria-label='Sortera mottagningar'
                        value={selectedSort ? "distance" : "alphabetical"}

                    >
                        {showDistanceSort ?
                            <option ref={sortDistanceOption} id="sort-distance-mobile" value="distance" aria-label="Sortera efter Avstånd">Avstånd</option> :
                            <option ref={sortDistanceOption} id="sort-distance-mobile" value="distance" className='disabled' disabled={true} aria-label="Sortera efter Avstånd">Avstånd</option>
                        }
                        <option id="sort-alphabetical-mobile" value="alphabetical" aria-label="Sortera efter A-Ö">A-Ö</option>
                    </select>
                </div>
            </div>
            <noscript>
                <button type='submit' aria-label='Filtrera mottagningar'>Filtrera</button>
            </noscript>
            <ul className={resultClassList} id='mottagningslista'>
                {currentVaccinationRecipiencies && currentVaccinationRecipiencies.testcenters.length !== 0 ? currentVaccinationRecipiencies.testcenters.map((recipiency) => {
                    return (
                        <li key={recipiency.hsaid}>
                            <VaccinationRecipiency recipiency={recipiency} showDistanceSort={showDistanceSort} selectedDateTimeInterval={selectedDateTimeInterval} />
                        </li>
                    )
                }) :
                    <li>
                        <article className='vgr-listitem'>Inga mottagningar hittade!</article>
                    </li>
                }
            </ul>
        </form>
    );
}