import { SubMenuItemsProps } from './types';

export const SubMenuItems: React.VFC<SubMenuItemsProps> = ({
  isSelected,
  menuItems,
  parentItem,
  ariaControlsId,
  arrowIconSvg,
}) => {
  let rowCount = '4';

  let rowsModifierCssClass = '';
  // Färre än 4 menyalternativ
  if (menuItems.length / 4 < 1) {
    rowCount = menuItems.length.toString();
    rowsModifierCssClass = 'main-menu__sub-items-wrapper--' + rowCount + '-rows';
    // Fler än 16 menyalternativ
  } else if (menuItems.length / 16 > 1) {
    rowCount = Math.ceil(menuItems.length / 4).toString();
    rowsModifierCssClass = 'main-menu__sub-items-wrapper--' + rowCount + '-rows';
  }

  return (
    <div
      id={ariaControlsId}
      className={`main-menu__content container-fluid rounded-corner-md-bottom-right dark ${
        isSelected ? 'main-menu__content--open' : ''
      }`}>
      <div className="main-menu__parent-item bg-vgr-primary-dark">
        <div className="container grid">
          <a className="g-col-12" href={parentItem.href}>
            {parentItem.text}
            <div dangerouslySetInnerHTML={{ __html: arrowIconSvg }}></div>
          </a>
        </div>
      </div>
      <div className="container">
        <ul className={`main-menu__sub-items-wrapper ${rowsModifierCssClass}`}>
          {menuItems.map((subItem) => (
            <li key={subItem.pageId} className="main-menu__sub-item">
              <a href={subItem.href}>{subItem.text}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
