import React, { useState } from "react";
import { IfeedItem } from "./IfeedItem";
import { IfeedListProps, IfeedDataDto } from "./types";

export const IfeedList = (props: IfeedListProps) => {
  const [ifeedList, setSortableList] = useState(props.ifeedList);
  const [state, setSortState] = useState("");

  const sortList = (key: string, ifeedList: IfeedDataDto[]) => {
    if (state == "Ascending") setSortState("Descending");
    else setSortState("Ascending");

    let sortedList: IfeedDataDto[];

    if (key == "createdDate") {
      sortedList = sortListByDateTime(key, state, ifeedList);
    } else {
      sortedList = sortListByString(key, state, ifeedList);
    }
    setSortableList(sortedList);
  };

  const sortListByString = (
    key: string,
    state: string,
    ifeedList: IfeedDataDto[]
  ) => {
    if (key !== "" && state == "Ascending") {
      return ifeedList.sort(
        (
          a: { [x: string]: { toString: () => string } },
          b: { [x: string]: { toString: () => any } }
        ) => a[key].toString().localeCompare(b[key].toString())
      );
    } else {
      return ifeedList.sort(
        (
          a: { [x: string]: { toString: () => any } },
          b: { [x: string]: { toString: () => string } }
        ) => b[key].toString().localeCompare(a[key].toString())
      );
    }
  };

  const sortListByDateTime = (
    key: string,
    state: string,
    ifeedList: IfeedDataDto[]
  ) => {
    const evaluate = (a, b) => (state === "Ascending" ? a - b : b - a);
    if (key !== "") {
      const sortedList = ifeedList.sort((a, b) => {
        const dateA = new Date(a[key]);
        const dateB = new Date(b[key]);
        return evaluate(dateA, dateB);
      });
      return sortedList;
    }
  };

  return (
    <table className="vgr-table vgr-table--zebra ifeed-block__table">
      <thead>
        <tr>
          <th>
            <button
              title={
                state == "Ascending"
                  ? "Sortera på titel i stigande ordning"
                  : "Sortera på titel i fallande ordning"
              }
              className="transparant-header"
              onClick={(e) => sortList("title", ifeedList)}
            >
              Titel {state == "Ascending" ? <> ▼</> : <>▲</>}
            </button>
          </th>
          {props.includeIfeedDate ? (
            <th>
              <button
                title={
                  state == "Ascending"
                    ? "Sortera på skapad i stigande ordning"
                    : "Sortera på skapad i fallande ordning"
                }
                className="transparant-header"
                onClick={(e) => sortList("createdDate", ifeedList)}
              >
                Skapad {state == "Ascending" ? <> ▼</> : <>▲</>}
              </button>
            </th>
          ) : (
            <></>
          )}
          {props.includeExpirationDate ? (
            <th>
              <button
                title={
                  state == "Ascending"
                    ? "Sortera på utgångsdatum i stigande ordning"
                    : "Sortera på utgångsdatum i fallande ordning"
                }
                className="transparant-header"
                onClick={(e) => sortList("expirationDate", ifeedList)}
              >
                Utgångsdatum {state == "Ascending" ? <> ▼</> : <>▲</>}
              </button>
            </th>
          ) : (
            <></>
          )}
        </tr>
      </thead>
      <tbody>
        {ifeedList.map((ifeed) => {
          return (
            <IfeedItem
              key={ifeed.id}
              ifeedItem={ifeed}
              isSofiaDocument={props.isSofiaDocument}
              includeIfeedDate={props.includeIfeedDate}
              includeExpirationDate={props.includeExpirationDate}
              showWarningIcon={props.showWarningIcon}
              isNative={props.isNative}
              addIcon={props.addIcon}
            />
          );
        })}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
};
