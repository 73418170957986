import React, { MutableRefObject, useState, useEffect, useCallback, useRef } from 'react';
import DOMPurify from 'dompurify';

type Props = {
  show: boolean;
  setShow: (show: boolean) => void;
  anchor: MutableRefObject<HTMLElement>;
  content: string;
  helpTextHeading: string;
};

export const Popper: React.VFC<Props> = (props) => {
  // Wrapping the PopperItem in a role=status container so screen readers
  // will announce the content when it mounts in the DOM
  return (
    <div role="status">
      <PopperItem {...props} />
    </div>
  );
};

const PopperItem: React.VFC<Props> = ({ show, setShow, anchor, content, helpTextHeading }) => {
  const popperRef = useRef<HTMLDivElement>(null);
  const [popperOffset, setPopperOffset] = useState(0);
  const [arrowOffset, setArrowOffset] = useState(0);

  // Will update the position of both Popper and Popper arrow to align with the provided anchor
  const updateOffsets = useCallback(() => {
    if (!anchor.current) return;
    setPopperOffset(anchor.current.offsetTop);
    setArrowOffset(anchor.current.offsetLeft);
  }, [anchor]);

  // Will update positions whenever [props.show] changes
  useEffect(() => updateOffsets(), [show, updateOffsets]);

  // Will update positions whenever browser viewport size changes
  useEffect(() => {
    const resizeUpdate = () => (show ? updateOffsets() : null);

    window.addEventListener('resize', resizeUpdate);
    return () => window.removeEventListener('resize', resizeUpdate);
  }, [show, updateOffsets]);

  // Will close the Popper when clicking outside, or pressing the ESC key
  useEffect(() => {
    const clickOutsideHandler = (event: MouseEvent) => {
      if (event.target instanceof Node && popperRef.current) {
        const isClickInside = popperRef.current.contains(event.target);
        if (!isClickInside) setShow(false);
      }
    };
    const escapeHandler = (event: KeyboardEvent) => {
      if (show && (event.key === 'Esc' || event.key === 'Escape')) {
        setShow(false);
      }
    };

    if (show) {
      document.addEventListener('click', clickOutsideHandler);
      document.addEventListener('keyup', escapeHandler);
    } else {
      document.removeEventListener('click', clickOutsideHandler);
      document.removeEventListener('keyup', escapeHandler);
    }
    return () => {
      document.removeEventListener('click', clickOutsideHandler);
      document.removeEventListener('keyup', escapeHandler);
    };
  }, [show, setShow]);

  return (
    show && (
      <div ref={popperRef} className="vgr-popper" data-show="true" style={{ top: `calc(${popperOffset}px + 45px)` }}>
        <div className="vgr-popper__arrow" style={{ left: `calc(${arrowOffset}px - 10px)` }}></div>
        <button onClick={() => setShow(!show)} className="vgr-popper__closebtn" type="button">
          {closeIcon}
        </button>
        <strong>{helpTextHeading}</strong>
        <p className="vgr-popper__body" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content)}}></p>
      </div>
    )
  );
};

const closeIcon = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.375 11.6245L11.625 0.374512"
      stroke="#494746"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.625 11.6245L0.375 0.374512"
      stroke="#494746"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
