import { AriaGuidanceProps, AriaOnChangeProps, AriaOnFilterProps, AriaOnFocusProps, GroupBase, OptionsOrGroups } from "react-select";

export  const swedishAriaLiveMessages = {
  guidance: (props: AriaGuidanceProps) => {
    const { isSearchable, isMulti, tabSelectsValue, context, isInitialFocus } = props;
    switch (context) {
      case 'menu':
        return ` Använd Upp och Ner för att välja alternativ, tryck på Enter för att markera det alternativ som för tillfället är i fokus, tryck på Escape för att lämna menyn${
          tabSelectsValue ? ', tryck på Tab för att markera alternativet och lämna menyn' : ''
        }.`;
      case 'input':
        return isInitialFocus
          ? `${props['aria-label'] || 'Kombinationsrutan'} är i fokus ${
              isSearchable ? ', skriv för att förfina listan' : ''
            }, tryck på Ner för att öppna menyn, ${isMulti ? ' tryck vänster för att markera valda värden' : ''}`
          : '';
      case 'value':
        return ' Använd vänster och höger för att växla mellan fokuserade värden, tryck på Backspace för att ta bort det aktuella fokuserade värdet';
      default:
        return '';
    }
  },

  onChange: <Option, IsMulti extends boolean>(props: AriaOnChangeProps<Option, IsMulti>) => {
    const { action, label = '', labels, isDisabled } = props;
    switch (action) {
      case 'deselect-option':
      case 'pop-value':
      case 'remove-value':
        return `alternativ ${label}, avmarkerad.`;
      case 'clear':
        return 'Alla markerade alternativ har rensats.';
      case 'initial-input-focus':
        return `alternativ${labels.length > 1 ? 'en' : ''} ${labels.join(', ')}, valda. `;
      case 'select-option':
        return isDisabled
          ? `alternativet ${label} är inaktiverad. Välj ett annat alternativ.`
          : `alternativ ${label}, markerad.`;
      default:
        return '';
    }
  },

  onFocus: <Option, Group extends GroupBase<Option>>(props: AriaOnFocusProps<Option, Group>) => {
    const { context, focused, options, label = '', selectValue, isDisabled, isSelected, isAppleDevice } = props;

    const getArrayIndex = (arr: OptionsOrGroups<Option, Group>, item: Option) =>
      arr && arr.length ? `${arr.indexOf(item) + 1} av ${arr.length}` : '';

    if (context === 'value' && selectValue) {
      return `valet ${label} är i fokus, ${getArrayIndex(selectValue, focused)}.`;
    }

    if (context === 'menu' && isAppleDevice) {
      const disabled = isDisabled ? ' inaktiverad' : '';
      const status = `${isSelected ? ' markerad' : ''}${disabled}`;
      return `${label}${status}, ${getArrayIndex(options, focused)}.`;
    }
    return '';
  },

  onFilter: (props: AriaOnFilterProps) => {
    const { inputValue, resultsMessage } = props;
    const words = resultsMessage.split(' ');
    const resultCount = words.length > 0 ? words[0] : 0;
    const swedishResultsMessage = resultCount + ' valbara resultat';
    return `${swedishResultsMessage}${inputValue ? ' för sökterm ' + inputValue : ''}.`;
  },
};