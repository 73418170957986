import { SecondaryNavigationProps } from './types';
import { NavigationItem } from './NavigationItem';

export const SecondaryNavigation: React.VFC<SecondaryNavigationProps> = ({ menuItems, rootPage, chevronIconSvg }) => {
  return (
    <nav aria-label="Undermeny">
      <div className="secondary-navigation__heading">
        <a href={rootPage.href}>{rootPage.text}</a>
      </div>
      <ul>
        {menuItems.map((item) => (
          <NavigationItem
            key={item.pageId}
            menuItem={item}
            parentIsExpanded={item.isInActiveChain}
            isRootItem={true}
            isLastChildOfRootItem={false}
            isVeryLastListItem={false}
            chevronIconSvg={chevronIconSvg}
          />
        ))}
      </ul>
    </nav>
  );
};
