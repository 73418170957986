import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import { enablePolyfills } from '../shared/shared.polyfills';
import { store } from '../shared/store/shared.store';
import { Provider } from 'react-redux';
import { SearchResponse } from '../shared/shared.models';
import { Facet, JobSearchItem } from './models';
import { JobSearchDocumentDataSetup } from './JobSearchDocumentDataSetup';

interface Props {
  searchResponse: string;
  dicriptionPageLink: string;
  initialQuery: string;
  defaultOrganization: string;
  workTitleFacets: Facet[];
  departmentFacets: Facet[];
  hireTypeFacets: Facet[];
  cityFacets: Facet[];
  resultPageLink: string;
  isPost: boolean;
  hasHeaderImage: boolean;
  chevronIconSvg: string;
  crossIconSvg: string;
}
export const JobSearchDocumentApp: React.VFC<Props> = ({
  searchResponse,
  dicriptionPageLink,
  initialQuery,
  defaultOrganization,
  workTitleFacets,
  departmentFacets,
  hireTypeFacets,
  cityFacets,
  resultPageLink,
  isPost,
  hasHeaderImage,
  chevronIconSvg,
  crossIconSvg,
}) => {
  const SSR = typeof window === 'undefined';
  if (!SSR) enablePolyfills();

  const initialSearchEngineResult = JSON.parse(searchResponse) as SearchResponse<JobSearchItem>;

  return (
    <Provider store={store}>
      {!SSR && (
        <BrowserRouter>
          <Routes>
            <Route
              path="*"
              element={
                <JobSearchDocumentDataSetup
                  searchResponse={initialSearchEngineResult}
                  dicriptionPageLink={dicriptionPageLink}
                  initialQuery={initialQuery}
                  defaultOrganization={defaultOrganization}
                  workTitleFacets={workTitleFacets}
                  departmentFacets={departmentFacets}
                  hireTypeFacets={hireTypeFacets}
                  cityFacets={cityFacets}
                  resultPageLink={resultPageLink}
                  isPost={isPost}
                  hasHeaderImage={hasHeaderImage}
                  chevronIconSvg={chevronIconSvg}
                  crossIconSvg={crossIconSvg}
                />
              }></Route>
          </Routes>
        </BrowserRouter>
      )}
      {SSR && (
        <StaticRouter location="/">
          <Routes>
            <Route
              path="*"
              element={
                <JobSearchDocumentDataSetup
                  searchResponse={initialSearchEngineResult}
                  dicriptionPageLink={dicriptionPageLink}
                  initialQuery={initialQuery}
                  defaultOrganization={defaultOrganization}
                  hireTypeFacets={hireTypeFacets}
                  departmentFacets={departmentFacets}
                  cityFacets={cityFacets}
                  resultPageLink={resultPageLink}
                  workTitleFacets={workTitleFacets}
                  isPost={isPost}
                  hasHeaderImage={hasHeaderImage}
                  chevronIconSvg={chevronIconSvg}
                  crossIconSvg={crossIconSvg}
                />
              }></Route>
          </Routes>
        </StaticRouter>
      )}
    </Provider>
  );
};
