import React from 'react';

interface Props {
  tail: number;
  showMoreText: string;
  setter: (isExpanded: boolean) => void;
}
export const ExpandMoreTopLevelItems: React.VFC<Props> = ({ tail, showMoreText, setter }) => {
  return (
    <li>
      <div className="vgr-checkbox-tree__head">
        <button
          className="vgr-searchservice__show-more-filters vgr-searchservice__linkbutton"
          onClick={() => setter(true)}
        >
          {showMoreText}
          {` (+ ${tail})`}
        </button>
      </div>
    </li>
  );
};
