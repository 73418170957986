import { useContext } from "react";
import { ActivePageContext, WizardContext } from "./store/wizard.store";
import { validateWizardFormData } from "./utils/ValidationUtil";

export const WizardBookingWithCode: React.VFC<WizardBookingWithCodeProps> = (props) => {
    let { activePage, setActivePage } = useContext(ActivePageContext);
    let { wizardFormData, setWizardFormData } = useContext(WizardContext);

    const onClickTreatmentType = (
        e: React.MouseEvent<HTMLButtonElement>
    ) => {
        const { value, name } = e.currentTarget;

        setWizardFormData((prevState) => ({
            ...prevState,
            selectedTreatmentType: { id: parseInt(value), description: name, content: "" },
            isBookingWithCode: true
        }));
        setActivePage(activePage + 1);
    };

    const onChangeWizardFormData = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setWizardFormData((prevState) => ({ ...prevState, [e.target.id]: e.target.value }))
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const errors = validateWizardFormData(wizardFormData, e.target.id);

        setWizardFormData((prevState) => ({ ...prevState, validationErrors: errors }))
    }

    return (
        <div className="g-col-12 expanded-block ftvwebbooking__expanded-block" >
            <details className="expandable-content expanded-block__details">
                <summary className="expanded-block__heading">
                    <h2>{props.titleBookingCode}</h2>
                    <div
                        dangerouslySetInnerHTML={{ __html: props.chevronIconSvg }}
                    ></div>
                </summary>
                <div className="expanded-block__content" dangerouslySetInnerHTML={{ __html: props.infoTextBookingCode }} />
                <div className="expanded-block__content grid">
                    <div className="g-col-6">
                        <label htmlFor="bookingCode">Bokningskod <span className="invalid">*</span></label>
                        <input
                            type="text"
                            name="bookingCode"
                            id="bookingCode"
                            placeholder="Ange din bokningskod"
                            onChange={onChangeWizardFormData}
                            onBlur={onBlur}
                        />
                        {Object.keys(wizardFormData.validationErrors).length > 0 && <span className="invalid">{wizardFormData.validationErrors["bookingCode"]}</span>}
                    </div>
                    <div className="g-col-6">
                        <label htmlFor="personalNumber">Personnummer <span className="invalid">*</span></label>
                        <input
                            type="text"
                            name="personalNumber"
                            id="personalNumber"
                            placeholder="ÅÅÅÅMMDDXXXX"
                            onChange={onChangeWizardFormData}
                            onBlur={onBlur}
                        />
                        {Object.keys(wizardFormData.validationErrors).length > 0 && <span className="invalid">{wizardFormData.validationErrors["personalNumber"]}</span>}
                    </div>
                </div>
                <div className="ftvwebbooking__expanded-block-button">
                    <button
                        type="button"
                        className="button-primary link-focus"
                        onClick={onClickTreatmentType}
                    >
                        Gå vidare
                    </button>
                </div>
                <div data-minimize="" className="expanded-block__minimize">
                    <button
                        type="button"
                        className="link-focus"
                        aria-controls="expandable-content-1074770"
                    >
                        Minimera
                        <div
                            dangerouslySetInnerHTML={{ __html: props.chevronIconSvg }}
                        ></div>
                    </button>
                </div>
            </details>
        </div>
    );
};