import { configureStore } from '@reduxjs/toolkit';
import { sharedSlice } from './shared.reducer';

export const store = configureStore({
  reducer: {
    shared: sharedSlice.reducer,
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
