import React, { useState, useRef, useEffect } from "react";
import { MobileMainMenuProps, MenuItem } from "./types";
import { ListItems } from "./ListItems";

export const MobileMainMenu: React.VFC<MobileMainMenuProps> = ({
  menuItems,
  parentItem,
  startPageId,
  currentPageId,
  logotypeSettings,
  searchSettings,
  toolNavigationHtml,
  chevronIconSvg,
  searchIconSvg,
  hasLogotype,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>();
  const [isSearchPanelOpen, setIsSearchPanelOpen] = useState<boolean>();
  const [activeMenuPage, setActiveMenuPage] = useState(parentItem);

  const [currentMenuItems, setCurrentMenuItems] = useState(menuItems);
  let isLoadingPages = false;
  const openCloseMenuButtonRef: React.RefObject<HTMLButtonElement> =
    useRef(null);

  useEffect(() => {
    const handleCloseMenu = (event: KeyboardEvent) => {
      if (event.key == "Escape") {
        if (isMenuOpen) onOpenMainMenuClick({});
        if (isSearchPanelOpen) onOpenSearchPanelClick({});
      }
    };

    document.addEventListener("keyup", handleCloseMenu);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("keyup", handleCloseMenu);
    };
  }, [isMenuOpen, isSearchPanelOpen]);

  const onOpenMainMenuClick = (event: any) => {
    // Tar bort vertikal scroll på sidan när menyn är öppen
    document.documentElement.classList.toggle("overflow-hidden");
    setIsMenuOpen((current) => !current);
    setIsSearchPanelOpen(false);
  };

  const onOpenSearchPanelClick = (event: any) => {
    setIsSearchPanelOpen((current) => !current);
  };

  async function onMenuNavigationClick(
    event: any,
    action: string,
    clickedItem: MenuItem
  ) {
    event.preventDefault();

    const fetchForPageId =
      action === "next" ? clickedItem.pageId : clickedItem.parentPageId;

    if (isLoadingPages) {
      console.log("Hämtar menyalterantiv...");
      return;
    }

    isLoadingPages = true;

    await fetch(
      "/api/fetchmenuitems?" +
        new URLSearchParams({
          pageId: fetchForPageId.toString(),
          currentPageId: currentPageId.toString(),
          langName: clickedItem.langName,
        })
    )
      .then((response) => response.json())
      .then((nextMenuItem: MobileMainMenuProps) => {
        setCurrentMenuItems(nextMenuItem.menuItems);

        const activeItem =
          action === "next" ? clickedItem : nextMenuItem.parentItem;

        setActiveMenuPage(activeItem);

        isLoadingPages = false;
      })
      .catch((error: Error) => {
        isLoadingPages = false;
        console.error("Error:", error);
      });
  }

  function Logotype() {
    let hasHeader: boolean =
      logotypeSettings.header !== null && logotypeSettings.header !== "";
    let hasCustomLogotype: boolean =
      logotypeSettings.imageUrl !== null && logotypeSettings.imageUrl !== "";

    return (
      <div className="logotype">
        <a
          className="logotype__mobile dark ignore-readability-rule"
          href={logotypeSettings.targetHref}
          aria-label={logotypeSettings.screenReaderLinkTextAriaLabel}
        >
          {(() => {
            {
              /* Visa enbart logotyp här om sitenamn inte är angivet. Annars visas logotypen ovanför navigeringen. */
            }
            if (!hasHeader && hasCustomLogotype) {
              return (
                <>
                  <div
                    className={`logotype__default ${logotypeSettings.customOrganizationCssClass}`}
                  >
                    <img
                      src={logotypeSettings.imageUrl}
                      alt={logotypeSettings.imageAltText}
                    />
                  </div>
                  <span className="visually-hidden">
                    {logotypeSettings.screenReaderLinkTextGeneric}
                  </span>
                </>
              );
            } else if (hasHeader) {
              return (
                <div>
                  <span>{logotypeSettings.header}</span>
                </div>
              );
            } else if (!logotypeSettings.hideDefaultLogotype) {
              return (
                <>
                  <div
                    className={`logotype__default ${logotypeSettings.customOrganizationCssClass}`}
                    dangerouslySetInnerHTML={{
                      __html: logotypeSettings.defaultLogotypeSvg,
                    }}
                  ></div>
                  <span className="visually-hidden">
                    {logotypeSettings.screenReaderLinkTextGeneric}
                  </span>
                </>
              );
            }
          })()}
        </a>
      </div>
    );
  }

  function SearchPanel() {
    const hiddenSearchParameters = searchSettings.searchParameters.map(
      (tuple, index) => (
        <input
          type="hidden"
          key={index}
          name={tuple.item1}
          value={tuple.item2}
        />
      )
    );

    return (
      <div
        id="mobile-search-panel"
        className={`mobile-main-menu__search-panel`}
      >
        <form
          action={searchSettings.searchPageUrl}
          method="get"
          role="search"
          className="global-search"
        >
          <div className="global-search__search-box-button-container">
            {hiddenSearchParameters}

            <input
              type="hidden"
              name="theme"
              value={searchSettings.primaryColorName}
            />
            <label className="visually-hidden" htmlFor="search-input-mobile">
              {searchSettings.label}
            </label>
            <input
              type="search"
              name="q"
              placeholder={searchSettings.inputPlaceholderText}
              id="search-input-mobile"
            />
            <button type="submit">
              <span className="visually-hidden">Sök</span>
              <img
                aria-hidden="true"
                src="/icons/visuell-identitet/search.svg"
                alt=""
              />
            </button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <>
      <nav
        className={
          "mobile-main-menu container-fluid p-0 m-0 " +
          (isMenuOpen || isSearchPanelOpen ? "mobile-main-menu--open" : "")
        }
      >
        <div
          data-menu-open={isMenuOpen ? "true" : "false"}
          data-search-panel-open={isSearchPanelOpen ? "true" : "false"}
        ></div>

        <div className="mobile-main-menu__header">
          {isMenuOpen &&
          activeMenuPage &&
          startPageId !== activeMenuPage.pageId ? (
            <>
              <button
                type="button"
                className="mobile-main-menu__previous-navigation link-focus dark"
                aria-label={`Visa undersidor till ${activeMenuPage.text}`}
                dangerouslySetInnerHTML={{ __html: chevronIconSvg }}
                onClick={(e) => {
                  activeMenuPage;
                  onMenuNavigationClick(e, "previous", activeMenuPage);
                }}
              ></button>
              <a href={activeMenuPage.href} className="dark">
                {activeMenuPage.text}
              </a>
            </>
          ) : (
            <Logotype />
          )}

          <div className="mobile-main-menu__navigation-bar">
            <div
              className={`mobile-main-menu__search-button ${
                isMenuOpen ? "d-none" : ""
              }`}
            >
              <button
                className="link-focus dark"
                aria-controls="mobile-search-panel"
                aria-expanded={isSearchPanelOpen ? "true" : "false"}
                onClick={onOpenSearchPanelClick}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: searchIconSvg }}
                ></span>
                <span>{isSearchPanelOpen ? "Stäng" : "Sök"}</span>
              </button>
            </div>

            <div className="mobile-main-menu__menu-button">
              <button
                ref={openCloseMenuButtonRef}
                className="link-focus dark"
                aria-controls="mobile-menu-content"
                aria-expanded={isMenuOpen ? "true" : "false"}
                onClick={onOpenMainMenuClick}
              >
                <span className="mobile-main-menu__burger">
                  <span className="mobile-main-menu__burger-one"></span>
                  <span className="mobile-main-menu__burger-two"></span>
                  <span className="mobile-main-menu__burger-three"></span>
                </span>
                <span>{isMenuOpen ? "Stäng" : "Meny"}</span>
              </button>
            </div>
          </div>
        </div>

        <SearchPanel />

        <div
          id="mobile-menu-content"
          tabIndex={
            -1
          } /* This prevents tab focus for elements with "overflow:scroll" on Firefox */
          className={`mobile-main-menu__content ${
            hasLogotype ? "mobile-main-menu__content--with-logotype" : ""
          }`}
        >
          <div className="mobile-main-menu__menu-items">
            <ListItems
              menuItems={currentMenuItems}
              chevronIconSvg={chevronIconSvg}
              onMenuNavigationClick={onMenuNavigationClick}
            />
          </div>
          <div dangerouslySetInnerHTML={{ __html: toolNavigationHtml }} />
        </div>
      </nav>
    </>
  );
};
