import { someDeep } from 'deepdash-es/standalone';
import cloneDeep from 'lodash.clonedeep';
import { Facet } from '../../shared.models';

// Determines if an item has any decendants who are "selected === true"
export const itemHasSelectedDecendants = (item: Facet) =>
  someDeep(item, (x) => x.selected, { childrenPath: ['children'], includeRoot: false });

// Recursively sets the indeterminate flag on items.
export const recurseSetIndeterminate = (items: Facet[]) => {
  items && items.forEach((item) => {
    const isIndeterminate = itemHasSelectedDecendants(item);
    item._indeterminate = isIndeterminate;
    if (isIndeterminate) item.selected = false;
    if (item.children?.length > 0) {
      recurseSetIndeterminate(item.children);
    }
  });
};

export const getSelectedItems = (rootItems: Facet[]) => {
  const selectedItems: Facet[] = [];
  const recurseSelected = (items: Facet[]) => {
    items && items.forEach((item) => {
      if (item.selected) selectedItems.push(item);
      if (item.children?.length > 0) recurseSelected(item.children);
    });
  };
  recurseSelected(rootItems);
  return selectedItems;
};

// Takes an array of facets and mutates the objects with extra capabilities for the client to use
export const mutateTreeItems = (items: Facet[]): [Facet[], Facet[]] => {
  const mutatedItems = cloneDeep(items);
  const leafNodes: Facet[] = [];
  const selectedItems: Facet[] = [];

  // Temporarily create parent references on all children so we can traverse the tree from the leaves and upwards
  const mutateItemsWithRefs = (item: Facet, parent: Facet, root: Facet) => {
    item._parent = parent;
    item._parentId = parent?.id || null;
    item._rootId = item === root ? null : root.id;
    if (item.selected) selectedItems.push(item);
    if (item.children.length === 0) leafNodes.push(item);
    item.children?.forEach((child) => mutateItemsWithRefs(child, item, root));
  };
  mutatedItems.forEach((item) => mutateItemsWithRefs(item, null, item));

  // Set the _shouldBeExpanded flag on all chained items (upwards) where a decendant is selected
  const mutateItemsWithShouldBeExpanded = (item: Facet, initExpanded: boolean) => {
    if (!item._initExpanded) item._initExpanded = initExpanded;
    if (item._parent) mutateItemsWithShouldBeExpanded(item._parent, item.selected || initExpanded);
    // Delete the parent reference when done because JSON does not allow circular references and the app will crash server side
    delete item._parent;
  };

  leafNodes.forEach((leaf) => mutateItemsWithShouldBeExpanded(leaf, false));

  // Set initial state of indeterminate boxes
  recurseSetIndeterminate(mutatedItems);
  return [mutatedItems, selectedItems];
};
