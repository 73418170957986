import { Facet } from "../shared/shared.models";

export interface OrganisationSearchItem {
  id: string;
  title: string;
  url: string;
  streetaddress: string;
  Coordinates: KivDataGeoCoordinates;
  latlon_0_coordinate: string;
  latlon_1_coordinate: string;
  geodist: string;
  phonenumber: string[];
  isOpen: boolean;
  IsClosingSoon: boolean;
  today: Today;
  openingHoursPerDay: KivOpeningHours[];
  opening_hours: KivOpeningHours[];
}
export interface OrganisationSearchFilterObject {
  clinics: CheckBox[];
  careTypes: CheckBox[];
  municipalityList: Facet[];
  hideMunicipality: boolean;
  hideClinics: boolean;
  hideCareType: boolean;
  searchQuery: string;
  coordsX: string;
  coordsY: string;
  organizationName: string;
  serviceParameters: string;
  queryCompletionServiceParameters: string;
  queryCompletionString: string;
  fullQueryCompletionResultString: string;
  resultPageId: number;
  myEnum: MyEnum;
}
export interface SelectListItem {
  text: string;
  value: string;
  selected: boolean;
}
export interface CheckBox {
  value: string;
  text: string;
  selected: boolean;
}
export interface KivOpeningHours {
  isToday: boolean;
  open: Date;
  close: Date;
  day: number;
  isOpen: boolean;
}
export interface Today {
  close: string;
}
export interface KivAddress {
  streetaddress: string;
  Postcode: string;
  Municipality: string;
}
export interface KivDataGeoCoordinates {
  CoordinatesX: string;
  CoordinatesY: string;
}

export interface SearchResponse<TDocumentType> {
  components: components<TDocumentType>;
  municipalityList: SelectListItem[];
}
export interface components<TDocumentType> {
  doclists: TDocumentType;
  sortOptions: sortOptions[];
}
export interface doclists<TDocumentType> {
  documents: TDocumentType[];
  hits: number;
}

export interface sortOptions {
  params: Params[];
  displayName: string;
  applied: boolean;
  query: string;
}
export interface Params {
  name: string;
  values: string[];
}

// Deklaration av enum
export enum MyEnum {
  "oppeningHours",
  "clinicTypes",
  "municipality",
}

const ParamKey = {
  KeyForClinicTypes: "filter4",
  KeyForMunicipality: "filter5",
};

const FilterKey = {
  NameForClinicTypes: "hsaBusinessClassificationCode:",
  NameForMunicipality: "hsaMunicipalityCode:",
};

export { ParamKey, FilterKey };
