import { Wizard } from "./Wizard"
import { WizardSearchStep } from "./WizardSearchStep";
import { WizardPersonalDataStep } from "./WizardPersonalDataStep";
import { WizardConfirmationStep } from "./WizardConfirmationStep";
import { WizardTreatmentType } from "./WizardTreatmentType";

export const WizardContainer: React.VFC<WizardContainerProps> = (props) => {
    return (
        <form action="/FtvBookingWizardPage/BookTime" method="post" className="grid g-col-12 vgr-form">
            <input
                type="hidden"
                name="__RequestVerificationToken"
                id="__RequestVerificationToken"
                value={props.antiforgeryToken}
            ></input>
            <Wizard>
                <WizardTreatmentType
                    treatmentTypes={props.treatmentTypes}
                    chevronIconSvg={props.chevronIconSvg}
                    infoTextFtvOnline={props.infoTextFtvOnline}
                    ftvOnlineLink={props.ftvOnlineLink}
                    infoTextBookingCode={props.infoTextBookingCode}
                    infoTextTreatmentTypes={props.infoTextTreatmentTypes}
                    ftvOnlineTitle={props.ftvOnlineTitle}
                    titleBookingCode={props.titleBookingCode}
                />
                <WizardSearchStep
                    selectOptions={props.additionalClinicInformation}
                    antiforgeryToken={props.antiforgeryToken}
                    searchResultIcon={props.searchResultIcon}
                    infoTextSearchResult={props.infoTextSearchResult}
                    infoTextLastMinuteSearchResult={props.infoTextLastMinuteSearchResult}
                    lastMinuteResultIcon={props.lastMinuteResultIcon}
                    activateLastMinute={props.activateLastMinute}
                    additionalClinicInformation={props.additionalClinicInformation}
                />
                <WizardPersonalDataStep antiforgeryToken={props.antiforgeryToken} infoTextMessageToClinic={props.infoTextMessageToClinic} infoTextGetPersonalData={props.infoTextGetPersonalData} />
                <WizardConfirmationStep />
            </Wizard>
        </form>
    )
}