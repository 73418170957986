import React from 'react';
import { SearchResponse } from '../shared/shared.models';
import { StyrandeDokument } from './models';
import { StyrandeDokumentComponent } from './StyrandeDokument';
import { useAppDispatch } from '../shared/store/store.hooks';
import { setInitialSearchResult, setSelectedCheckboxTree } from '../shared/store/shared.reducer';
import { getSelectedItems } from '../shared/components/CheckboxTree/checkboxtree.utils';
import { CopySearchSettings } from '../shared/components';
import { ErrorHandler } from '../shared/components/ErrorHandler';

interface Props {
  searchResponse: SearchResponse<StyrandeDokument>;
  isEditorLoggedIn: boolean;
}
export const StyrandeDokumentDataSetup: React.VFC<Props> = ({ searchResponse, isEditorLoggedIn }) => {
  const dispatch = useAppDispatch();
  dispatch(setInitialSearchResult(searchResponse));

  dispatch(
    setSelectedCheckboxTree({
      id: searchResponse.facet.id,
      selectedFacets: getSelectedItems(searchResponse.facet.children),
    })
  );
  dispatch(
    setSelectedCheckboxTree({
      id: searchResponse.subjectFacets.id,
      selectedFacets: getSelectedItems(searchResponse.subjectFacets.filters),
    })
  );

  return (
    <>
      {isEditorLoggedIn && <CopySearchSettings />}
      <ErrorHandler />
      <StyrandeDokumentComponent />
    </>
  );
};
