import moment from "moment";
import React, { useEffect, useState } from "react";
import { KeyMatchItemProps } from "./types";
import { async } from "rxjs";

export const KeyMatchItem = (props: KeyMatchItemProps) => {
  const [isActive, setIsActive] = useState(props.KeyMatch.active);
  const editKeyMatchUrl = `Edit?id=${props.KeyMatch.id}`;
  const [antiforgeryToken, setAntiforgeryToken] = useState(
    props.antiforgeryToken
  );

  useEffect(() => {
    setIsActive(isActive);
  }, [isActive]);

  async function fetchActivating() {
    const active = !isActive;
    props.KeyMatch.active = active;

    const formData = new FormData();
    formData.append("Active", props.KeyMatch.active.toString());
    formData.append("Id", props.KeyMatch.id.toString());

    setIsActive(active);

    const requestOptions = {
      method: "POST",
      headers: {
        "Requestverificationtoken": antiforgeryToken,
      },
      body: formData,
    };

    const response = await fetch(
      `/api/hittakeymatch/changeactivestate`,
      requestOptions
    );
    if (response.status !== 200) {
      alert(
        `Något gick fel, vänligen ladda om sidan. Svaret returnerade statuskod: ${response.status}`
      );
    } else if (response.status == 200) {
      console.log("fetch returned ok");
    }
  }

  const handleClick = () => {
    fetchActivating().catch(function (err) {
      alert(`Något gick fel, vänligen ladda om sidan. Error: ${err}`);
    });
  };
  const handleDeleteClick = () => {
    const confirmed = window.confirm(
      "Vill du ta bort den här sponsrade länken?"
    );
    if (confirmed) props.deleteCallback(props.KeyMatch.id.toString());
  };

  return (
    <tr className="">
      <td>
        <span className="word-wrap">{props.KeyMatch.titel}</span>
      </td>
      <td>
        <span className="word-wrap">{props.KeyMatch.scope}</span>
      </td>
      <td className="active">
        <>{}</>
        <div className="word-wrap word-wrap__active">
          {props.KeyMatch.active ? "Ja" : "Nej"}
        </div>
      </td>
      <td>
        <span className="word-wrap">{props.KeyMatch.description}</span>
      </td>
      <td>
        <span className="word-wrap">{props.KeyMatch.terms}</span>
      </td>
      <td>
        <div className="word-wrap">
          {props.KeyMatch.created_at !== ""
            ? moment(props.KeyMatch.created_at)
                .locale("sv")
                .format("DD MMMM yyyy")
            : ""}
        </div>
      </td>
      <td>
        <div className="word-wrap">
          {props.KeyMatch.updated_at !== ""
            ? moment(props.KeyMatch.updated_at)
                .locale("sv")
                .format("DD MMMM yyyy")
            : ""}
        </div>
      </td>

      <td>
        <span className="word-wrap">{props.KeyMatch.email}</span>
      </td>
      <td>
        <a href={props.KeyMatch.url?.toString()} className="word-wrap url">
          {props.KeyMatch.url?.toString()}{" "}
        </a>
      </td>
      {(props.UserIsKeyMatchSuperAdmin ||
        (props.ScopesUserMayEdit != null &&
          props.ScopesUserMayEdit.length > 0)) && (
        <>
          <td>
            <a
              className={
                props.UserIsKeyMatchSuperAdmin ||
                (props.ScopesUserMayEdit != null &&
                  props.KeyMatch.scope != null &&
                  props.ScopesUserMayEdit.some((x) =>
                    props.KeyMatch.scope
                      .replace(" ", "")
                      .split(",")
                      .some((y) => x == y)
                  ))
                  ? ""
                  : "disabledAnchor"
              }
              href={editKeyMatchUrl}
            >
              Redigera
            </a>
          </td>
          <td>
            <button
              className={
                props.UserIsKeyMatchSuperAdmin ||
                (props.ScopesUserMayEdit != null &&
                  props.KeyMatch.scope != null &&
                  props.ScopesUserMayEdit.some((x) =>
                    props.KeyMatch.scope
                      .replace(" ", "")
                      .split(",")
                      .some((y) => x == y)
                  ))
                  ? ""
                  : "disabledAnchor"
              }
              onClick={handleClick}
            >
              {isActive ? "Deaktivera" : "Aktivera"}
            </button>
          </td>
          <td>
            <button
              className={
                props.UserIsKeyMatchSuperAdmin ||
                (props.ScopesUserMayEdit != null &&
                  props.KeyMatch.scope != null &&
                  props.ScopesUserMayEdit.some((x) =>
                    props.KeyMatch.scope
                      .replace(" ", "")
                      .split(",")
                      .some((y) => x == y)
                  ))
                  ? ""
                  : "disabledAnchor"
              }
              onClick={handleDeleteClick}
            >
              Ta bort
            </button>
          </td>
        </>
      )}
    </tr>
  );
};
