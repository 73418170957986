import React, { FormEvent, useRef, useState } from "react";
import { QueryEntity, SearchResponse } from "../shared/shared.models";
import { Facet, JobSearchItem } from "./models";
import { useSearchEngine, useClientOnly } from "../shared/hooks";
import { JobSearchResultItem } from "./JobSearchResultItem";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "../shared/components";
import {
  selectIsLoading,
  selectSearchResult,
} from "../shared/store/shared.reducer";
import { useAppSelector } from "../shared/store/store.hooks";
import { Filtering } from "./Components/Filtering";
import { Sorting } from "../shared/components/Sorting";
interface Props {
  dicriptionPageLink: string;
  initialQuery: string;
  defaultOrganization: string;
  workTitleFacets: Facet[];
  departmentFacets: Facet[];
  hireTypeFacets: Facet[];
  cityFacets: Facet[];
  resultPageLink: string;
  isPost: boolean;
  hasHeaderImage: boolean;
  chevronIconSvg: string;
  crossIconSvg: string;
}
export const JobSearchDocument: React.VFC<Props> = ({
  dicriptionPageLink,
  initialQuery,
  defaultOrganization,
  workTitleFacets,
  departmentFacets,
  hireTypeFacets,
  cityFacets,
  resultPageLink,
  isPost,
  hasHeaderImage,
  chevronIconSvg,
  crossIconSvg,
}) => {
  /*********************************
   * Variables
   *********************************/
  // const path = window.location.pathname;
  const [doSearch] = useSearchEngine("Search/FilterJobSearchDocument");
  const searchResult = useAppSelector(
    selectSearchResult
  ) as SearchResponse<JobSearchItem>;
  const queryString = initialQuery === "*" ? "" : initialQuery;
  const isHydrated = useClientOnly();
  const isLoading = useAppSelector(selectIsLoading);
  const [searchParams] = useSearchParams();
  let index = 0;
  const { numberOfHits } = searchResult.documentList;
  const excludeSortOptions = ["From (ASC)", "To (DESC)", "Title Z-A"];
  const searchField = useRef<HTMLInputElement>(null);
  const nameSelectOptionRef = useRef<HTMLSelectElement>(null);
  const departmentSelectOptionRef = useRef<HTMLSelectElement>(null);
  const workFormSelectOptionRef = useRef<HTMLSelectElement>(null);
  const sortList: QueryEntity[] = [];

  /*********************************
   * Functions
   *********************************/

  function onSearchSubmit(e: FormEvent<HTMLFormElement>) {
    if (!isPost) return;

    e.preventDefault();

    // Delete potential offset to reset pagination to page 1
    searchParams.delete("offset");
    searchParams.set("q", searchField.current.value || "*");

    doSearch(searchParams);
  }

  function clearAllFilters() {
    if (
      nameSelectOptionRef.current &&
      nameSelectOptionRef.current.childNodes.length > 0
    ) {
      nameSelectOptionRef.current.childNodes.forEach(
        (option: HTMLOptionElement) => {
          option.removeAttribute("selected");
        }
      );
    }
    if (
      departmentSelectOptionRef.current &&
      departmentSelectOptionRef.current.childNodes.length > 0
    ) {
      departmentSelectOptionRef.current.childNodes.forEach(
        (option: HTMLOptionElement) => {
          option.removeAttribute("selected");
        }
      );
    }
    if (
      workFormSelectOptionRef.current &&
      workFormSelectOptionRef.current.childNodes.length > 0
    ) {
      workFormSelectOptionRef.current.childNodes.forEach(
        (option: HTMLOptionElement) => {
          option.removeAttribute("selected");
        }
      );
    }

    doSearch(
      "q=" +
        (searchField.current.value.length > 0
          ? searchField.current.value
          : "*") +
        "&isPost=true"
    );
  }

  searchResult.documentList.sortOptions.forEach((item, index) => {
    const value = new URLSearchParams(item.query).get("sort");
    const option = { ...item, value };
    if (index === searchResult.documentList.sortOptions.length - 1) {
      if (item.displayName == "Relevance") {
        option.displayName = "Relevans";
        option.value = value;
        sortList.unshift(option);
      }
    } else if (!excludeSortOptions.some((x) => x == item.displayName)) {
      if (item.displayName == "From (DESC)") {
        option.displayName = "Publiceringsdatum";
        option.value = value;
      } else if (item.displayName == "To (ASC)") {
        option.displayName = "Ansökningsdatum";
        option.value = value;
      } else if (item.displayName == "Title A-Z") {
        option.displayName = "A-Ö";
        option.value = value;
      }
      sortList.push(option);
    }
  });
  /*********************************
   * Render
   *********************************/
  return (
    <div className={isLoading ? " loading " : ""}>
      <div className="job-search-block__colored-background">
        <div className="container">
          <div className="job-search-block__search-field-container">
            {!hasHeaderImage && <h2>Klart att du ska jobba hos oss</h2>}

            <form
              className="job-search-block__search-field"
              action={resultPageLink}
              method="get"
              onSubmit={(e) => onSearchSubmit(e)}
            >
              <input type="hidden" value="true" name="isPost"></input>
              <div className="grid">
                <div className="g-col-12 job-search-block__search-box-button-container">
                  <label className="visually-hidden" id="q">
                    Fritext för jobbsök
                  </label>
                  <input
                    type="search"
                    name="q"
                    placeholder="Sök lediga jobb"
                    defaultValue={queryString}
                    ref={searchField}
                  ></input>
                  {isPost && (
                    <a
                      className="job-search-block__skip-link"
                      href="#jobSearchHits"
                    >
                      Gå direkt till träfflistan
                    </a>
                  )}

                  <button type="submit">
                    <img
                      aria-hidden="true"
                      src="~/icons/visuell-identitet/search.svg"
                      alt=""
                    ></img>
                    <span>Sök lediga jobb</span>
                  </button>
                </div>
              </div>
              <Filtering
                searchResult={searchResult}
                workTitleFacets={workTitleFacets}
                organisationFacets={departmentFacets}
                hireTypeFacets={hireTypeFacets}
                cityFacets={cityFacets}
                isLoading={isLoading}
                isPost={isPost}
                defaultOrganization={defaultOrganization}
                nameSelectOptionRef={nameSelectOptionRef}
                departmentSelectOptionRef={departmentSelectOptionRef}
                workFormSelectOptionRef={workFormSelectOptionRef}
              ></Filtering>
              {isPost && (
                <button
                  className="link-focus"
                  onClick={() => clearAllFilters()}
                  type="reset"
                  disabled={isLoading}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: crossIconSvg }}
                  ></span>
                  Rensa filter
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
      {isPost ? (
        searchResult.documentList != undefined &&
        searchResult.documentList.documents.length > 0 ? (
          <Sorting
            isHydrated={isHydrated}
            sortList={sortList}
            isLoading={isLoading}
            numberOfHits={numberOfHits}
            mobileFormClassName="job-search-block__sorting-mobile"
            endPoint="Search/FilterJobSearchDocument"
            hiddenClassName="visually-hidden"
            resultHeaderClassName="container search-result-block__sorting-container"
            searchQuery=""
            sectionClassName=""
            desktopResultSortingClassName="search-result-block__sorting"
            buttonClassName="link-focus"
            hasMobileButton={false}
            isFilterSectionOpen={false}
            setFilterSectionOpen={null}
            activeFiltersCount={0}
            filterIcon={null}
            path={window.location.origin + window.location.pathname}
          />
        ) : (
          <div className="container">
            Din sökning genererade inga träffar, förfina din sökning och försök
            igen.
          </div>
        )
      ) : (
        ""
      )}

      {isPost && (
        <section
          className="container search-result-block pt-3"
          id="jobSearchHits"
        >
          <h3 className="visually-hidden">Sektion för sökresultat</h3>
          <ul className="grid">
            {searchResult.documentList.documents.map((x) => (
              <li key={x.id + index++} className="g-col-12">
                <JobSearchResultItem
                  item={x}
                  dicriptionPageLink={dicriptionPageLink}
                  chevronIconSvg={chevronIconSvg}
                />
              </li>
            ))}
          </ul>
        </section>
      )}
      {isPost && searchResult.documentList.documents != undefined && (
        <Pagination
          scrollToId="searchResultsHeaderSection"
          cssClass="job-search-block"
          endPoint="Search/FilterJobSearchDocument"
          searchResult={searchResult}
          path={window.location.origin + window.location.pathname}
        />
      )}
    </div>
  );
};
