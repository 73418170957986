import React from 'react';

interface Props {
  isExpanded: boolean;
  clickCallback: () => void;
}

export const ExpandButton: React.VFC<Props> = ({ isExpanded, clickCallback }) => {
  return (
    <button
      aria-label={isExpanded ? 'Stäng underliggande alternativ' : 'Öppna underliggande alternativ'}
      onClick={clickCallback}
      type="button"
      className="vgr-checkbox-tree__toggle"
    >
      <span className="vgr-checkbox-tree__icon vgr-checkbox-tree__icon--expanded-true vgr-searchservice__filterarrow">
        <svg className="vgr-searchservice__filterarrow" aria-hidden={true}>
          <use xlinkHref="#streamline-arrow"></use>
        </svg>
      </span>
      <span className="vgr-checkbox-tree__icon vgr-checkbox-tree__icon--expanded-false vgr-searchservice__filterarrow">
        <svg className="vgr-searchservice__filterarrow" aria-hidden={true}>
          <use xlinkHref="#streamline-arrow"></use>
        </svg>
      </span>
    </button>
  );
};
