import React, { useState } from 'react';
import { Facet } from '../../../shared.models';
import { ExpandMoreTopLevelItems } from './ExpandMoreTopLevelItems';
import { Item } from './Item';

interface Props {
  treeId: string;
  maxTopLevelItems: number;
  items: Facet[];
  checkboxInteracted: (item: Facet) => void;
}
export const Tree: React.VFC<Props> = ({ treeId, maxTopLevelItems, items, checkboxInteracted }) => {
  const [topLevelIsExpanded, setTopLevelIsExpanded] = useState(false);
  return (
    <ul className="vgr-checkbox-tree-list">
      {items.map((item, index) => {
        if (!topLevelIsExpanded && maxTopLevelItems) {
          let tail = items.length - maxTopLevelItems;
          if (tail === 1) tail++;
          const threshold = items.length - tail;

          if (index < threshold) {
            return (
              <Item
                key={item.id}
                maxTopLevelItems={maxTopLevelItems}
                treeId={treeId}
                item={item}
                checkboxInteracted={checkboxInteracted}
              />
            );
          } else if (index === items.length - 1) {
            return (
              <ExpandMoreTopLevelItems
                key={`show-more-filter-${treeId}`}
                setter={setTopLevelIsExpanded}
                tail={tail}
                showMoreText={'Visa fler ämnen'}
              />
            );
          }
        } else {
          return (
            <Item
              key={item.id}
              maxTopLevelItems={maxTopLevelItems}
              treeId={treeId}
              item={item}
              checkboxInteracted={checkboxInteracted}
            />
          );
        }
      })}
    </ul>
  );
};
