import React from 'react';
import dayjs from 'dayjs';
import { JobSearchItem } from './models';

interface Props {
  item: JobSearchItem;
  dicriptionPageLink: string;
  chevronIconSvg: string;
}
export const JobSearchResultItem: React.VFC<Props> = ({ item, dicriptionPageLink, chevronIconSvg }) => {
  /*********************************
   * Render
   *********************************/
  return (
    <div>
      <article className="badge">
        <header className="badge__content-wrapper">
          <h3 className="visually-hidden">{item.title}</h3>
          <div className="search-result-block__heading-meta-container">
            <a href={dicriptionPageLink.length > 0 ? dicriptionPageLink + '?jobid=' + item.id : ''}>
              <span className="search-result-block__heading">{item.title}</span>
            </a>
            <div className="search-result-block__sub-heading ignore-readability-rule">
              {item.job_ad_department && <span>{item.job_ad_department}</span>}
              {item.city && (
                <span>
                  {' '}
                  &#x2022; <span> {item.city}</span>
                </span>
              )}
              {item.job_ad.type && (
                <span>
                  {' '}
                  &#x2022; <span>{item.job_ad.type}</span>
                </span>
              )}
            </div>
          </div>
          <div className="badge__shape">
            <span>Sista ansökningsdag</span>
            {item.job_ad_date_to && <span> {dayjs(item.job_ad_date_to).format('D MMMM YYYY')}</span>}
          </div>
        </header>
        <div className="search-result-block__body">
          <details>
            <summary className="link-focus">
              <span className="details-title-show">Visa mer uppgifter</span>
              <span className="details-title-hide">Dölj mer uppgifter</span>
              <span className="visually-hidden">om {item.title}</span>
              <span dangerouslySetInnerHTML={{ __html: chevronIconSvg }}></span>
            </summary>
            <div className="search-result-block__details-content">
              <div className="search-result-block__meta-group">
                {item.job_ad_department && (
                  <div>
                    <b>Förvaltning</b>
                    <div>
                      <span>{item.job_ad_department}</span>
                    </div>
                  </div>
                )}
                {item.job_ad_name && (
                  <div>
                    <b>Yrke</b>
                    <div>
                      <span>{item.job_ad_name}</span>
                    </div>
                  </div>
                )}
                {item.job_ad_date_from && (
                  <div>
                    <b>Publicerad</b>
                    <div>
                      <span>{dayjs(item.job_ad_date_from).format('D MMMM YYYY')}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="search-result-block__meta-group">
                {item.job_ad.org_desc && (
                  <div>
                    <b>Verksamhet</b>
                    <div>
                      <span>{item.job_ad.org_desc}</span>
                    </div>
                  </div>
                )}
                {item.city && (
                  <div>
                    <b>Ort</b>
                    <div>
                      <span>{item.city}</span>
                    </div>
                  </div>
                )}
                {item.job_ad_date_to && (
                  <div>
                    <b>Sista ansökningsdag</b>
                    <div>
                      <span>{dayjs(item.job_ad_date_to).format('D MMMM YYYY')}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="search-result-block__meta-group">
                {item.job_ad.type && (
                  <div>
                    <b>Anställningsform</b>
                    <div>
                      <span>{item.job_ad.type}</span>
                    </div>
                  </div>
                )}
                {item.job_ad_reference && (
                  <div>
                    <b>Referensnummer</b>
                    <div>
                      <span>{item.job_ad_reference}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </details>
        </div>
      </article>
    </div>
  );
};
