import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { StaticRouter } from 'react-router-dom/server';
import { VaccinationsPage } from './VaccinationsPage';
import { ApiModel, MunicipalityFilter } from './types';

export function VaccinationsPageContainer(props: { apiResponse: ApiModel, apiUrl: string, municipalitiesAndDistrictsResponse: MunicipalityFilter[], pageUrl: string, municipality: string }){
    return (
        typeof window !== "undefined" ? 
        <BrowserRouter>
            <VaccinationsPage apiResponse={props.apiResponse} apiUrl={props.apiUrl} municipalitiesAndDistrictsResponse={props.municipalitiesAndDistrictsResponse} pageUrl={props.pageUrl} municipality={props.municipality} />
        </BrowserRouter> : 
        <StaticRouter location="/">
            <VaccinationsPage apiResponse={props.apiResponse} apiUrl={props.apiUrl} municipalitiesAndDistrictsResponse={props.municipalitiesAndDistrictsResponse} pageUrl={props.pageUrl} municipality={props.municipality} />
        </StaticRouter>
    )
}
