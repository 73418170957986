import { createContext } from "react";

interface ActivePageContext {
    activePage: number;
    setActivePage: React.Dispatch<React.SetStateAction<number>>
}

interface WizardContext {
    wizardFormData: WizardFormData;
    setWizardFormData: React.Dispatch<React.SetStateAction<WizardFormData>>;
}

export const ActivePageContext = createContext<ActivePageContext>({ activePage: 0, setActivePage: () => { } });
export const WizardContext = createContext<WizardContext>({ wizardFormData: {} as WizardFormData, setWizardFormData: () => { } });