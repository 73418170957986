import React from "react";
import { useSearchEngine } from "../hooks";
import { QueryEntity } from "../shared.models";

interface Props {
  isLoading: boolean;
  numberOfHits: number;
  sortList: QueryEntity[];
  isHydrated: boolean;
  mobileFormClassName: string;
  endPoint: string;
  hiddenClassName: string;
  sectionClassName: string;
  resultHeaderClassName: string;
  searchQuery: string;
  desktopResultSortingClassName: string;
  buttonClassName: string;
  hasMobileButton: boolean;
  isFilterSectionOpen: boolean;
  setFilterSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activeFiltersCount: number;
  filterIcon: React.ReactNode;
}

export const Sorting: React.VFC<Props> = (props) => {
  const [doSearch] = useSearchEngine(props.endPoint);
  // Function to handle sort selection
  const handleSortChange = (query: string) => {
    // Call doSearch with the selected query
    doSearch(query);
  };
  const chevron = (
    <svg aria-hidden={true} style={{ width: "16px", height: "16px" }}>
      <use xlinkHref="#streamline-arrow"></use>
    </svg>
  );
  function toggleFilterPanel() {
    document.getElementsByTagName("html")[0].classList.toggle("backdrop");
    props.setFilterSectionOpen(!props.isFilterSectionOpen);
  }
  return (
    <section
      id="searchResultsHeaderSection"
      className={`${props.sectionClassName} ${
        props.isHydrated ? "" : "ssr-only"
      }`}
      aria-label="Sektion med sammanfattning av sökresultat samt funktioner för sortering"
    >
      <div className={props.resultHeaderClassName}>
        {" "}
        {/* Skärmläsare läser upp "Genomför sökning" direkt när en sökning görs */}
        {props.isLoading && (
          <div
            className={`sr-only ${props.hiddenClassName}`}
            aria-live="assertive"
            aria-atomic="true"
          >
            Genomför sökning
          </div>
        )}
        {/* Skärmläsare läser upp antalet träffar när den är klar med allt annat (polite) */}
        <div role="status">
          <strong>
            {props.numberOfHits}{" "}
            {props.searchQuery != undefined && props.searchQuery.length > 0 ? (
              <>dokument{props.searchQuery}</>
            ) : (
              <>träffar</>
            )}
          </strong>
        </div>
        <div
          className={`${props.desktopResultSortingClassName} ${
            props.isHydrated ? "" : "ssr-only" + props.hiddenClassName
          }`}
        >
          <span id="sort-label">Sortera efter:</span>{" "}
          {props.sortList.map((x, i, arr) => {
            if (props.isHydrated) {
              return (
                <React.Fragment key={x.query + x.displayName}>
                  <button
                    className={
                      props.buttonClassName +
                      (x.defaultDisabled
                        ? " disabled"
                        : x.selected
                        ? " selected"
                        : props.isLoading
                        ? " loading"
                        : "")
                    }
                    aria-label={`Sortera efter ${x.displayName}`}
                    disabled={
                      x.selected || props.isLoading || x.defaultDisabled
                    }
                    onClick={() => handleSortChange(x.query)}
                    type="button"
                  >
                    {x.displayName}
                  </button>
                  {i + 1 < arr.length && " | "}
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={x.query + x.displayName}>
                  <a
                    aria-label={`Sortera efter ${x.displayName}`}
                    href={"?" + x.query}
                    className={props.buttonClassName}
                  >
                    {x.displayName}
                  </a>
                  {i + 1 < arr.length && " | "}
                </React.Fragment>
              );
            }
          })}
        </div>
      </div>
      <form className={props.mobileFormClassName} hidden={!props.isHydrated}>
        {props.hasMobileButton && (
          <button
            className="vgr-searchservice__outlined-button"
            onClick={() => toggleFilterPanel()}
            type="button"
            aria-expanded={props.isFilterSectionOpen}
            aria-controls="searchfiltersection"
          >
            {props.filterIcon}
            Filtrera sökning{" "}
            {props.activeFiltersCount > 0 && (
              <span>({props.activeFiltersCount})</span>
            )}
          </button>
        )}
        <label className="visually-hidden" htmlFor="sort">
          Sortering
        </label>
        <select
          aria-labelledby="sort-label"
          id="sort"
          value={props.sortList.find((x) => x.selected)?.displayName}
          onChange={(e) => {
            const options = e.target.options;
            const selectedOption = options[options.selectedIndex];
            const query = selectedOption.getAttribute("data-query") ?? ""; // Använd nullish coalescing operator
            handleSortChange(query); // Call handleSortChange with the selected query
          }}
          disabled={props.isLoading}
        >
          {props.sortList.map((x) => {
            return (
              <option
                key={`${x.query + x.displayName}_option`}
                value={x.displayName}
                data-query={x.query}
              >
                {x.displayName}
              </option>
            );
          })}
        </select>
      </form>
    </section>
  );
};
