import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import { enablePolyfills } from '../shared/shared.polyfills';
import { store } from '../shared/store/shared.store';
import { Provider } from 'react-redux';
import { SearchResponse } from '../shared/shared.models';
import { StyrandeDokument } from './models';
import { StyrandeDokumentDataSetup } from './StyrandeDokumentDataSetup';

interface Props {
  searchResponse: string;
  isEditorLoggedIn: boolean;
}
export const StyrandeDokumentApp: React.VFC<Props> = ({ searchResponse, isEditorLoggedIn }) => {
  const SSR = typeof window === 'undefined';
  if (!SSR) enablePolyfills();

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    html.classList.add('vgr-searchservice__html');
  }, []);

  const initialSearchEngineResult = JSON.parse(searchResponse) as SearchResponse<StyrandeDokument>;

  return (
    <Provider store={store}>
      {!SSR && (
        <BrowserRouter>
          <Routes>
            <Route
              path="*"
              element={
                <StyrandeDokumentDataSetup
                  searchResponse={initialSearchEngineResult}
                  isEditorLoggedIn={isEditorLoggedIn}
                />
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      )}
      {SSR && (
        <StaticRouter location="/">
          <Routes>
            <Route
              path="*"
              element={
                <StyrandeDokumentDataSetup
                  searchResponse={initialSearchEngineResult}
                  isEditorLoggedIn={isEditorLoggedIn}
                />
              }
            ></Route>
          </Routes>
        </StaticRouter>
      )}
    </Provider>
  );
};
