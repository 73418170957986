import React from "react";
import { SearchResult } from "../shared/shared.models";
import { useAppDispatch } from "../shared/store/store.hooks";
import { setInitialSearchResponse } from "../shared/store/shared.reducer";
import { OrganisationSearchDocument } from "./OrganisationSearchDocument";
import { OrganisationSearchItem } from "./models";

interface Props {
  searchResponse: string;
  chevronIconSvg: string;
  initialQuery: string;
  isPost: string;
}
export const OrganisationSearchDocumentDataSetup: React.VFC<Props> = ({
  searchResponse,
  chevronIconSvg,
  initialQuery,
  isPost,
}) => {
  const initialSearchEngineResult = JSON.parse(
    searchResponse
  ) as SearchResult<OrganisationSearchItem>;
  const dispatch = useAppDispatch();
  dispatch(setInitialSearchResponse(initialSearchEngineResult));

  return (
    <>
      <OrganisationSearchDocument
        initialQuery={initialQuery}
        isPost={isPost}
        chevronIconSvg={chevronIconSvg}
      />
    </>
  );
};
