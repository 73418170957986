import React, { useRef } from "react"
import { VaccinationRecipiencyProps } from "../types";

export const VaccinationRecipiency: React.VFC<VaccinationRecipiencyProps> = ({ recipiency, showDistanceSort, selectedDateTimeInterval }) => {
    const description = useRef<HTMLDivElement>();

    let ariaText = recipiency.municipalityCode !== null && recipiency.municipalityCode !== undefined && recipiency.municipalityName + " kommun";
    ariaText += recipiency.municipalityCode !== null && recipiency.municipalityCode !== undefined && recipiency.district !== null && recipiency.district !== undefined && " " + recipiency.district;
    ariaText += recipiency.distance && showDistanceSort && " " + Math.round(recipiency.distance * 10) / 10 + " km från dig";

    return (
        <>
            <article className="vgr-listitem">
                <header>
                    <>
                        <a className='vgr-listitem__header-link' href={recipiency.websiteUrl} aria-describedby={recipiency.hsaid}>
                            <h3 className="vgr-listitem__heading">{recipiency.title}</h3>
                        </a>
                        <h4 className="vgr-searchservice-item__sub-heading" aria-label={ariaText}>
                            {recipiency.municipalityCode !== null &&
                                recipiency.municipalityCode !== undefined &&
                                <>{recipiency.municipalityName + " kommun"}</>
                            }
                            {recipiency.municipalityCode !== null &&
                                recipiency.municipalityCode !== undefined &&
                                recipiency.district !== null &&
                                recipiency.district !== undefined &&
                                <> • {recipiency.district}</>
                            }
                            {recipiency.distance ? showDistanceSort &&
                                <> • {Math.round(recipiency.distance * 10) / 10} km från dig</> :
                                recipiency.latitude == null && recipiency.longitude == null && showDistanceSort && <> • Uppgifter om avstånd saknas</>
                            }
                        </h4>
                    </>
                    <div id={recipiency.hsaid} className="vgr-listitem__badge-wrapper">
                        {(recipiency.timeslots !== null && recipiency.timeslots !== 0 && recipiency.timeslots !== 1 && recipiency.timeslots < 100) && (selectedDateTimeInterval === "alla-kommande-tider" || selectedDateTimeInterval == undefined) &&
                            <div className="vgr-badge vgr-badge--success">{recipiency.timeslots + " bokningsbara tider"}</div>
                        }
                        {(recipiency.timeslots !== null && recipiency.timeslots !== 0 && recipiency.timeslots !== 1 && recipiency.timeslots > 100) && (selectedDateTimeInterval === "alla-kommande-tider" || selectedDateTimeInterval == undefined) &&
                            <div className="vgr-badge vgr-badge--success">Fler än 100 bokningsbara tider</div>
                        }
                        {(recipiency.timeslots !== null && recipiency.timeslots !== 0 && recipiency.timeslots == 1) && (selectedDateTimeInterval === "alla-kommande-tider" || selectedDateTimeInterval == undefined) &&
                            <div className="vgr-badge vgr-badge--success">{recipiency.timeslots + " bokningsbar tid"}</div>
                        }
                        {(recipiency.timeslots !== null && recipiency.timeslots !== 0 && recipiency.timeslots !== 1 && recipiency.timeslots < 100) && selectedDateTimeInterval !== undefined && selectedDateTimeInterval !== "alla-kommande-tider" &&
                            <div className="vgr-badge vgr-badge--success">{recipiency.timeslots + " bokningsbara tider inom " + selectedDateTimeInterval}</div>
                        }
                        {(recipiency.timeslots !== null && recipiency.timeslots !== 0 && recipiency.timeslots !== 1 && recipiency.timeslots > 100) && selectedDateTimeInterval !== undefined && selectedDateTimeInterval !== "alla-kommande-tider" &&
                            <div className="vgr-badge vgr-badge--success">{"Fler än 100 bokningsbara tider inom " + selectedDateTimeInterval}</div>
                        }
                        {(recipiency.timeslots !== null && recipiency.timeslots !== 0 && recipiency.timeslots == 1) && selectedDateTimeInterval !== undefined && selectedDateTimeInterval !== "alla-kommande-tider" &&
                            <div className="vgr-badge vgr-badge--success">{recipiency.timeslots + " bokningsbar tid inom " + selectedDateTimeInterval}</div>
                        }
                        {(recipiency.timeslots == null || recipiency.timeslots == 0) && (selectedDateTimeInterval === "alla-kommande-tider" || selectedDateTimeInterval == undefined) &&
                            <div className="vgr-badge vgr-badge--warning">Inga bokningsbara tider just nu</div>
                        }
                        {(recipiency.timeslots == null || recipiency.timeslots == 0) && selectedDateTimeInterval !== undefined && selectedDateTimeInterval !== "alla-kommande-tider" &&
                            <div className="vgr-badge vgr-badge--warning">{"Inga bokningsbara tider inom " + selectedDateTimeInterval}</div>
                        }
                        {recipiency.dropin &&
                            <div className="vgr-badge vgr-badge--info">Drop-in erbjuds vissa tider</div>
                        }
                    </div>
                </header>
                {(recipiency.provisionText || recipiency.dropinText || recipiency.address) &&
                    <div className="vgr-listitem__expandable-body">
                        {(recipiency.dropinText !== null && recipiency.dropinText !== undefined && recipiency.dropin && recipiency.address) ||
                            (recipiency.phoneNumber !== null && recipiency.phoneNumber !== undefined) ||
                            (recipiency.provisionText !== null && recipiency.provisionText !== undefined) ? (
                            <details>
                                <summary>
                                    <span>
                                        <span className="title-show">Visa</span>
                                        <span className="title-hide">Dölj</span> mer uppgifter
                                        <span className='sr-only'>&nbsp;om {recipiency.title}</span>
                                    </span>{' '}{chevron}
                                </summary>
                                {recipiency.dropinText !== null &&
                                    recipiency.dropinText !== undefined &&
                                    recipiency.dropin &&
                                    recipiency.address &&
                                    <div className="vgr-listitem__expandable__nested-details-body">
                                        <strong>Adress</strong>
                                        <br />
                                        {recipiency.address.streetAdress}, {recipiency.address.zipCode}, {recipiency.address.city}
                                    </div>
                                }
                                {recipiency.phoneNumber !== null &&
                                    recipiency.phoneNumber !== undefined &&
                                    <div className="vgr-listitem__expandable__nested-details-body">
                                        <strong>Telefonnummer</strong>
                                        <br />
                                        {recipiency.phoneNumber}
                                    </div>
                                }
                                {recipiency.provisionText !== null &&
                                    recipiency.provisionText !== undefined &&
                                    <div className="vgr-listitem__expandable__nested-details-body">
                                        <strong>Kompletterande information</strong>
                                        <br />
                                        <div dangerouslySetInnerHTML={{ __html: recipiency.provisionText }}></div>
                                    </div>
                                }
                            </details>
                        ) : null
                        }
                        {(recipiency.dropinText || recipiency.address !== null) &&
                            <div ref={description} className="vgr-listitem__description">
                                {recipiency.dropinText !== null &&
                                    recipiency.dropinText !== undefined &&
                                    recipiency.dropin ?
                                    <>
                                        <strong>Information om drop-in</strong>
                                        <br />
                                        <div dangerouslySetInnerHTML={{ __html: recipiency.dropinText }}></div>
                                    </> :
                                    <>
                                        <div>
                                            <strong>Adress</strong>
                                            <br />
                                            {recipiency.address.streetAdress}, {recipiency.address.zipCode}, {recipiency.address.city}
                                        </div>
                                    </>
                                }
                            </div>
                        }
                    </div>
                }
                {(recipiency.urlBooking != null && recipiency.urlBooking != "" && !(recipiency.timeslots == null || recipiency.timeslots == 0)) ? (
                    <>
                        <div className="vgr-listitem__divider2" />
                        <footer>
                            <ul>
                                <li>
                                    <a href={recipiency.urlBooking}>Boka tid via webben hos {recipiency.title}</a>
                                </li>
                            </ul>
                        </footer>
                    </>
                ) : null
                }
            </article>
        </>
    );
}

const chevron = (
    <svg aria-hidden={true} style={{ width: '16px', height: '16px' }}>
        <use xlinkHref="#streamline-arrow"></use>
    </svg>
);