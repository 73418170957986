import { ListItemsProps } from './types';

export const ListItems: React.VFC<ListItemsProps> = ({ onMenuNavigationClick, menuItems, chevronIconSvg }) => {
  return (
    <ul>
      {menuItems.map((item, index) => {
        return (
          <li key={item.pageId} className={item.isCurrentPage ? 'mobile-main-menu__menu-items--selected dark' : ''}>
            <a href={item.href} className={`ignore-readability-rule ${item.isCurrentPage ? 'ignore-link-focus' : ''}`}>
              {item.text}
            </a>
            {item.hasChildren && (
              <button
                aria-label={`Visa undersidor till ${item.text}`}
                className="link-focus"
                onClick={(e) => {
                  onMenuNavigationClick(e, 'next', item);
                }}
                dangerouslySetInnerHTML={{ __html: chevronIconSvg }}></button>
            )}
          </li>
        );
      })}
    </ul>
  );
};
