import React, { useState } from "react";
import { AddEditKeyMatch } from "./types";
import Select from "react-select";
import { data, error } from "cypress/types/jquery";

export const AddEdit = (props: AddEditKeyMatch) => {
  const [selectedScopes, setSelectedScopes] = useState([]);
  const options = props.allScopes;
  const [antiforgeryToken, setAntiforgeryToken] = useState(
    props.antiforgeryToken
  );
  const [responseMessage, setResponseMessage] = useState({
    successMessage: "",
    errorMessage: "",
    errorheading: "",
    heading: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    Titel: "",
    Description: "",
    Terms: "",
    Url: "",
    Email: "",
    SelectedScope: "",
  });
  const [defaultValidationErrsors, setDefaultValidationErrors] = useState({
    Titel: props.keyMatch.titel != "" ? "" : "Fältet är obligatoriskt",
    Description:
      props.keyMatch.description != "" ? "" : "Fältet är obligatoriskt",
    Terms: props.keyMatch.terms != "" ? "" : "Fältet är obligatoriskt",
    Url: props.keyMatch.url != "" ? "" : "Fältet är obligatoriskt",
    Email: props.keyMatch.email != "" ? "" : "Fältet är obligatoriskt",
    SelectedScope:
      props.keyMatch.selectedScope != "" ? "" : "Fältet är obligatoriskt",
  });
  const defaultScopeValues = (x: string) => {
    return { text: x, value: x };
  };

  const [KeyMatchData, setStateProperties] = useState({
    Titel: props.keyMatch.titel,
    Terms: props.keyMatch.terms,
    SelectedScope:
      props.keyMatch.selectedScope != ""
        ? props.keyMatch.selectedScope
            ?.split(",")
            .map((x) => defaultScopeValues(x))
        : null,
    Url: props.keyMatch.url,
    Description: props.keyMatch.description,
    Email: props.keyMatch.email,
    Id: props.keyMatch.id.toString(),
  });
  const [formData, setFormData] = useState({
    Titel: KeyMatchData.Titel,
    Terms: KeyMatchData.Terms,
    SelectedScope:
      KeyMatchData.SelectedScope != null ||
      KeyMatchData.SelectedScope != undefined
        ? KeyMatchData.SelectedScope.map((x) => x.value).join(",")
        : "",
    Url: KeyMatchData.Url,
    Scope:
      KeyMatchData.SelectedScope != null ||
      KeyMatchData.SelectedScope != undefined
        ? KeyMatchData.SelectedScope.map((x) => x.value).join(",")
        : "",
    Description: KeyMatchData.Description,
    Email: KeyMatchData.Email,
    Id: KeyMatchData.Id,
  });
  function handleTextBoxChanges(evt) {
    const value = evt.target.value;
    setStateProperties({ ...KeyMatchData, [evt.target.name]: value });
    setFormData({ ...formData, [evt.target.name]: value });
    if (value == "") {
      setValidationErrors({
        ...validationErrors,
        [evt.target.name]: "Fältet är obligatoriskt",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        [evt.target.name]: "",
      });
    }
    if (value != "") {
      setDefaultValidationErrors({
        ...defaultValidationErrsors,
        [evt.target.name]: "",
      });
    }
  }
  function selectOnChange(data: []) {
    console.log("on change = " + JSON.stringify(data));
    setSelectedScopes(data);
    KeyMatchData.SelectedScope = data;
    if (data != null || data != undefined) {
      setDefaultValidationErrors({
        ...defaultValidationErrsors,
        SelectedScope: "",
      });
      setFormData({
        ...formData,
        SelectedScope: KeyMatchData.SelectedScope.map((x) => x.value).join(","),
      });
      setFormData({
        ...formData,
        Scope: KeyMatchData.SelectedScope.map((x) => x.value).join(","),
      });
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Requestverificationtoken": antiforgeryToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      };
      const response = await fetch(
        "/api/hittakeymatch/addkeymatch",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // Handle the JSON response from the server here
      const data = await response.json();
      setResponseMessage(data); // Update the state with the response message
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <>
        {responseMessage.successMessage != "" && (
          <>
            <div className="notification-message">
              <div className="vgr-message vgr-message--success">
                <div className="vgr-message__description">
                  {responseMessage.heading && (
                    <>
                      <strong className="vgr-message__title">
                        {responseMessage.heading}
                      </strong>
                    </>
                  )}
                  {responseMessage.successMessage}
                </div>
              </div>
            </div>
          </>
        )}

        {responseMessage.errorMessage != "" && (
          <>
            <div className="notification-message">
              <div className="vgr-message vgr-message--warning">
                <div className="vgr-message__description">
                  {responseMessage.errorheading && (
                    <>
                      <strong className="vgr-message__title">
                        {responseMessage.errorheading}
                      </strong>
                    </>
                  )}

                  {responseMessage.errorMessage}
                </div>
              </div>
            </div>
          </>
        )}
      </>

      <form onSubmit={handleSubmit} method="post" className="vgr-form">
        <input
          type="hidden"
          name="__RequestVerificationToken"
          id="__RequestVerificationToken"
          value={antiforgeryToken}
        ></input>
        <input
          type="hidden"
          name="active"
          id="active"
          value={props.keyMatch.active?.toString()}
        ></input>
        <input
          type="hidden"
          name="Id"
          id="Id"
          value={props.keyMatch.id?.toString()}
        ></input>
        <input
          type="hidden"
          name="created_at"
          id="created_at"
          value={props.keyMatch.created_at}
        ></input>
        <input
          type="hidden"
          name="updated_at"
          id="updated_at"
          value={props.keyMatch.updated_at}
        ></input>
        <input
          type="hidden"
          name="SelectedScope"
          id="SelectedScope"
          value={
            selectedScopes != null && selectedScopes.length > 0
              ? selectedScopes.map((x) => x.Value).join(",")
              : KeyMatchData.SelectedScope != null
              ? KeyMatchData.SelectedScope.map((x) => x.value).join(",")
              : []
          }
        ></input>
        <div className="vgr-form__item">
          <label htmlFor="Titel" asp-for="Titel" className="vgr-form__label">
            Titel*
          </label>
          <input
            className="vgr-form__input"
            id="Titel"
            name="Titel"
            value={KeyMatchData.Titel}
            asp-for="Titel"
            onChange={handleTextBoxChanges}
            placeholder='T.ex. "Alla utbildningar i Västra Götaland"'
            type="text"
          ></input>
          {validationErrors.Titel && (
            <span
              className={
                validationErrors.Titel == ""
                  ? "field-validation-valid vgr-form__helper"
                  : "vgr-form__helper"
              }
              data-valmsg-replace="false"
            >
              {validationErrors.Titel}
            </span>
          )}
        </div>

        <div className="vgr-form__item">
          <label htmlFor="Terms" className="vgr-form__label">
            Nyckelord*
          </label>
          <input
            className="vgr-form__input"
            id="Terms"
            name="Terms"
            data-val="true"
            value={KeyMatchData.Terms}
            onChange={handleTextBoxChanges}
            data-val-required="The Terms field is required."
            placeholder='Använd bara gemener. Ange en kommaseparerad lista av nyckelord, t.ex. "skola, studera"'
            type="text"
          ></input>
          {validationErrors.Terms && (
            <span
              className={
                validationErrors.Terms == ""
                  ? "field-validation-valid vgr-form__helper"
                  : "vgr-form__helper"
              }
              data-valmsg-replace="false"
            >
              {validationErrors.Terms}
            </span>
          )}
        </div>

        <div className="vgr-form__item">
          <label htmlFor="SelectedScope" className="vgr-form__label">
            Scope*
          </label>

          <Select
            isMulti={true}
            closeMenuOnSelect={false}
            name="SelectedScope"
            options={options != null ? options : []}
            onChange={selectOnChange}
            value={
              KeyMatchData.SelectedScope != null
                ? KeyMatchData.SelectedScope
                : selectedScopes != null
                ? selectedScopes
                : []
            }
            getOptionLabel={(option) =>
              option.value != ""
                ? option.text + " (" + option.value + ")"
                : option.text
            }
            getOptionValue={(option) => option}
          />

          {validationErrors.SelectedScope && (
            <span
              className={
                validationErrors.SelectedScope == ""
                  ? "field-validation-valid vgr-form__helper"
                  : "vgr-form__helper"
              }
              data-valmsg-replace="false"
            >
              {validationErrors.SelectedScope}
            </span>
          )}
        </div>

        <div className="vgr-form__item">
          <label htmlFor="Url" className="vgr-form__label">
            Url*
          </label>
          <input
            className="vgr-form__input"
            id="Url"
            name="Url"
            value={KeyMatchData.Url}
            onChange={handleTextBoxChanges}
            data-val="true"
            data-val-required="The Url field is required."
            placeholder='T.ex. "http://www.exempel.se/utbildningar"'
            type="text"
          ></input>
          {validationErrors.Url && (
            <span
              className={
                validationErrors.Url == ""
                  ? "field-validation-valid vgr-form__helper"
                  : "vgr-form__helper"
              }
              data-valmsg-replace="false"
            >
              {validationErrors.Url}
            </span>
          )}
        </div>

        <div className="vgr-form__item">
          <label
            htmlFor="Description"
            asp-for="Description"
            className="vgr-form__label"
          >
            Beskrivning*
          </label>
          <textarea
            className="vgr-form__textarea"
            id="Description"
            name="Description"
            data-val="true"
            asp-for="Description"
            value={KeyMatchData.Description}
            onChange={handleTextBoxChanges}
            data-val-required="The Description field is required."
            placeholder='T.ex. "Här hittar du alla utbildningar i Västra Götaland"'
          ></textarea>

          <span
            className={
              validationErrors.Description == ""
                ? "field-validation-valid vgr-form__helper"
                : "vgr-form__helper"
            }
          >
            {validationErrors.Description}
          </span>
        </div>

        <div className="vgr-form__item">
          <label htmlFor="Email" className="vgr-form__label">
            E-post*
          </label>
          <input
            className="vgr-form__input"
            id="Email"
            name="Email"
            data-val="true"
            value={KeyMatchData.Email}
            onChange={handleTextBoxChanges}
            data-val-email="The Email field is not a valid e-mail address."
            data-val-required="The Email field is required."
            placeholder='Ange din epostadress, t.ex. "test.testsson@vgregion.se"'
            type="text"
          ></input>
          {validationErrors.Email && (
            <span
              className={
                validationErrors.Email == ""
                  ? "field-validation-valid vgr-form__helper"
                  : "vgr-form__helper"
              }
              data-valmsg-replace="false"
            >
              {validationErrors.Email}
            </span>
          )}
        </div>
        <button
          type="submit"
          value="Spara"
          disabled={
            Object.values(validationErrors).some(
              (value) => value.trim() !== ""
            ) ||
            Object.values(defaultValidationErrsors).some(
              (value) => value.trim() !== ""
            )
          }
          className="vgr-matchbutton"
        >
          Spara
        </button>
        <div className="align-right">
          <a className="vgr-matchbutton" href={props.backUrl}>
            Tillbaka
          </a>
        </div>
      </form>
    </div>
  );
};
