import React, { useEffect, useState } from "react";
import {
  CheckBox,
  MyEnum,
  OrganisationSearchFilterObject,
  SelectListItem,
} from "../models";
import { IOption, MultiSelect } from "./MultiSelect";
import { GroupBase, SelectInstance } from "react-select";

interface Props {
  isLoading: boolean;
  isPost: boolean;
  organisationSearchFilterObject: OrganisationSearchFilterObject;
  checkboxInteracted: (
    item: CheckBox,
    key: number,
    selected: boolean,
    index: number,
    ref: React.RefObject<HTMLInputElement>
  ) => void;
  removeEmptyParams: () => string;
  searchParameters: URLSearchParams;
  municipalityList: SelectListItem[];
  opningsTimeRef: React.RefObject<HTMLInputElement>[];
  clinicsTypesRef: React.RefObject<HTMLInputElement>[];
  multiSelectRef: React.RefObject<
    SelectInstance<IOption, true, GroupBase<IOption>>
  >;
  setForceUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Filtering: React.VFC<Props> = ({
  isLoading,
  organisationSearchFilterObject,
  checkboxInteracted,
  searchParameters,
  removeEmptyParams,
  municipalityList,
  isPost,
  multiSelectRef,
  opningsTimeRef,
  clinicsTypesRef,
  setForceUpdate,
}) => {
  const [opningsTime, setOpningsTime] = useState(opningsTimeRef);
  const [clinicsTypes, setClinicsTypes] = useState(clinicsTypesRef);
  const allfilterValues = searchParameters.getAll("filter4");
  useEffect(() => {
    setForceUpdate(false);
    setClinicsTypes(clinicsTypesRef);
    setOpningsTime(opningsTimeRef);
  }, [clinicsTypesRef, opningsTimeRef, setForceUpdate]);
  return (
    <fieldset className="">
      <legend className={"visually-hidden"}>Filtrera sökning</legend>
      <div className="grid search-control-block__filtering">
        {organisationSearchFilterObject.hideMunicipality == false && (
          <div className="g-col-md-3 g-col-12">
            <fieldset>
              <legend className="">Välj kommun</legend>
              <MultiSelect
                itemsId="filter5"
                itemsName="filter5"
                municipalityList={municipalityList}
                isLoading={isLoading}
                endPoint={"search/FilterOrganisationSearchDocument"}
                filterKey={"hsaMunicipalityCode:"}
                removeEmptyParams={removeEmptyParams}
                isPost={isPost}
                multiSelectRef={multiSelectRef}
              />
            </fieldset>
          </div>
        )}
        {organisationSearchFilterObject.hideClinics == false && (
          <div className="g-col-md-3 g-col-12">
            <fieldset>
              <legend className="">Mottagningen har</legend>

              {organisationSearchFilterObject.clinics.map((clinic, index) => (
                <div className="grid" key={clinic.value + index.toString()}>
                  <input
                    type="checkbox"
                    name={clinic.value}
                    id={clinic.value}
                    disabled={isLoading}
                    onChange={(event) =>
                      checkboxInteracted(
                        clinic,
                        MyEnum.oppeningHours,
                        event.target.checked,
                        index,
                        opningsTimeRef[index]
                      )
                    }
                    value={
                      opningsTime[index]?.current?.value?.toString() ??
                      clinic.selected?.toString()
                    }
                    checked={
                      opningsTime[index]?.current?.checked ?? clinic.selected
                    }
                    ref={opningsTime[index]}
                  />
                  <label
                    htmlFor={clinic.value}
                    className="search-control-block__label no-bold"
                  >
                    {clinic.text}
                  </label>
                </div>
              ))}
            </fieldset>
          </div>
        )}
        {!organisationSearchFilterObject.hideCareType && (
          <div className="g-col-md-3 g-col-12">
            <fieldset>
              <legend className="">Välj typ av mottagning</legend>

              {organisationSearchFilterObject.careTypes.map((careType, i) => (
                <div className="grid" key={careType.value + i.toString()}>
                  <input
                    type="checkbox"
                    name={"filter4"}
                    id={`filter4_${careType.value}`} // Use a unique identifier based on careType.Value
                    disabled={isLoading}
                    ref={clinicsTypes[i]}
                    onChange={(event) =>
                      checkboxInteracted(
                        careType,
                        MyEnum.clinicTypes,
                        event.target.checked,
                        i,
                        clinicsTypesRef[i]
                      )
                    }
                    value={
                      clinicsTypes[i] != undefined &&
                      clinicsTypes[i].current != null
                        ? careType.value
                        : careType.selected ||
                          (allfilterValues != undefined &&
                            allfilterValues.find((x) =>
                              x.includes(careType.value)
                            ))
                        ? careType.value
                        : "false"
                    }
                    checked={
                      clinicsTypes[i] != undefined &&
                      clinicsTypes[i].current != null
                        ? clinicsTypes[i]?.current?.checked
                        : careType.selected ||
                          (allfilterValues !== undefined &&
                            allfilterValues.length > 0 &&
                            allfilterValues.find((x) =>
                              x?.includes(careType.value)
                            ))
                        ? true
                        : false
                    }
                  />

                  <label
                    htmlFor={`filter4_${careType.value}`}
                    className="search-control-block__label no-bold"
                  >
                    {careType.text}
                  </label>
                </div>
              ))}
            </fieldset>
          </div>
        )}
      </div>
    </fieldset>
  );
};
