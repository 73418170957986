import React from "react";
import dayjs from "dayjs";
import { IfeedItemProps } from "./types";

export const IfeedItem = (props: IfeedItemProps) => {
  return (
    <tr>
      <td className="vgr-toggle media print-hide">
        <div>
          {props.addIcon ? (
            <div className="media__aside">
              <button
                type="button"
                className="vgr-toggle__button ifeed-block__iconwrapper"
                aria-label={"Mer information om" + props.ifeedItem.title}
                data-tab
                aria-expanded="false"
                data-isexpanded="false"
              >
                <svg
                  fill="#FFFFFF"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="i-icon"
                >
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"></path>
                </svg>
                <svg
                  fill="#fff"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </button>
            </div>
          ) : (
            <></>
          )}
          <div className="media-body">
            {!props.isNative ? (
              <a
                href={props.ifeedItem.url}
                title={props.ifeedItem.title}
                className="ifeed-block__title vgr-link--pdf"
              >
                {props.ifeedItem.title}
              </a>
            ) : (
              <>
                {props.ifeedItem.title_filename_native != "" &&
                props.ifeedItem.title_filename_native != null ? (
                  <a
                    href={props.ifeedItem.downloadNativeUrl}
                    title={props.ifeedItem.title_filename_native}
                    className="ifeed-block__title"
                  >
                    <span>{props.ifeedItem.title_filename_native}</span>
                  </a>
                ) : (
                  <a
                    href={props.ifeedItem.downloadNativeUrl}
                    title={props.ifeedItem.title}
                    className="ifeed-block__title"
                  >
                    <span>{props.ifeedItem.title}</span>
                  </a>
                )}
              </>
            )}
          </div>
          {props.addIcon ? (
            <div
              className="vgr-toggle__panel ifeed-block__moreinfo"
              data-panel
              tabIndex={-1}
              data-isexpanded="false"
            >
              {props.isSofiaDocument ? (
                <>
                  {props.ifeedItem.title != "" &&
                  props.ifeedItem.title != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">Titel</div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.title}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.description != "" &&
                  props.ifeedItem.description != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Beskrivning
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.description}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.coreArchivalObjectProducer != "" &&
                  props.ifeedItem.coreArchivalObjectProducer != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">Myndighet</div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.coreArchivalObjectProducer}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.classificatinStructur != "" &&
                  props.ifeedItem.classificatinStructur != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Klassificeringsstruktur (process)
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.classificatinStructur}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.objectType != "" &&
                  props.ifeedItem.objectType != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Handlingstyp
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.objectType}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.publishedForUnit != null &&
                  props.ifeedItem.publishedForUnit != undefined ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Publicerat för enhet
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.publishedForUnit.map((item) => {
                          return item;
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.validityArea != null &&
                  props.ifeedItem.validityArea != undefined ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Giltighetsområde
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.validityArea.map((item) => {
                          return item;
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.subjectClassification != null &&
                  props.ifeedItem.subjectClassification != undefined ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Regional ämnesindelning
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.subjectClassification.map((item) => {
                          return item;
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem
                    .vgrsyDomainExtensionVgrsySubjectLocalClassification !=
                    null &&
                  props.ifeedItem
                    .vgrsyDomainExtensionVgrsySubjectLocalClassification !=
                    undefined ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Egen ämnesindelning
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.vgrsyDomainExtensionVgrsySubjectLocalClassification.map(
                          (item) => {
                            return item;
                          }
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.companiesKeywords != "" &&
                  props.ifeedItem.companiesKeywords != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Företagsnyckelord
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.companiesKeywords}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.documentId != "" &&
                  props.ifeedItem.documentId != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        DokumentId källa
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.documentId}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.source != "" &&
                  props.ifeedItem.source != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">Källa</div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.source}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.createdByUnit != "" &&
                  props.ifeedItem.createdByUnit != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Upprättat av enhet
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.createdByUnit}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.createdBy != "" &&
                  props.ifeedItem.createdBy != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Upprättat av (person)
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.createdBy}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.createdDateTime != "" &&
                  props.ifeedItem.createdDateTime != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Upprättad datum
                      </div>
                      <div className="ifeed-block__info-content">
                        {dayjs(props.ifeedItem.createdDateTime).format(
                          "D MMMM YYYY"
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.validFrom != "" &&
                  props.ifeedItem.validFrom != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Giltig fr o m
                      </div>
                      <div className="ifeed-block__info-content">
                        {dayjs(props.ifeedItem.validFrom).format("D MMMM YYYY")}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.validTo != "" &&
                  props.ifeedItem.validTo != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">Valid to</div>
                      <div className="ifeed-block__info-content">
                        {dayjs(props.ifeedItem.validTo).format("D MMMM YYYY")}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.dc_date_Availablefrom != "" &&
                  props.ifeedItem.dc_date_Availablefrom != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Tillgänglig från
                      </div>
                      <div className="ifeed-block__info-content">
                        {dayjs(props.ifeedItem.dc_date_Availablefrom).format(
                          "D MMMM YYYY"
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.dc_date_Availableto != "" &&
                  props.ifeedItem.dc_date_Availableto != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Tillgänglig till
                      </div>
                      <div className="ifeed-block__info-content">
                        {dayjs(props.ifeedItem.dc_date_Availableto).format(
                          "D MMMM YYYY"
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.securityClass != "" &&
                  props.ifeedItem.securityClass != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Åtkomsträtt
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.securityClass}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.swemesh_name != null &&
                  props.ifeedItem.swemesh_name != undefined ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">SweMeSH</div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.swemesh_name.map((item) => {
                          return item;
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.hsa_verksamhetskod != null &&
                  props.ifeedItem.hsa_verksamhetskod != undefined ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Verksamhetskod enligt HSA
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.hsa_verksamhetskod.map((item) => {
                          return item;
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.contentResponsible != null &&
                  props.ifeedItem.contentResponsible != undefined ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Innehållsansvarig
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.contentResponsible.map((item) => {
                          return item;
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.contentResponsibleRole != null &&
                  props.ifeedItem.contentResponsibleRole != undefined ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Innehållsansvarig, roll
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.contentResponsibleRole.map((item) => {
                          return item;
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.aprovedOff != null &&
                  props.ifeedItem.aprovedOff != undefined ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Godkänd av
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.aprovedOff.map((item) => {
                          return item;
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.aprovedOffRole != null &&
                  props.ifeedItem.aprovedOffRole != undefined ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Godkänd av, roll
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.aprovedOffRole.map((item) => {
                          return item;
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.version != "" &&
                  props.ifeedItem.version != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">Version</div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.version}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.url != "" && props.ifeedItem.url != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Länk för webben
                      </div>
                      <div className="ifeed-block__info-content">
                        <a href={props.ifeedItem.url}>{props.ifeedItem.url}</a>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.downloadNativeUrl != "" &&
                  props.ifeedItem.downloadNativeUrl != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Länk för webben original
                      </div>
                      <div className="ifeed-block__info-content">
                        <a href={props.ifeedItem.downloadNativeUrl}>
                          {props.ifeedItem.downloadNativeUrl}
                        </a>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.siteInfoName != null &&
                  props.ifeedItem.siteInfoName != undefined ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Ursprunglig samarbetsyta
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.siteInfoName.map((item) => {
                          return item;
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {props.ifeedItem.dc_description != "" &&
                  props.ifeedItem.dc_description != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Beskrivning
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.dc_description}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.dc_publisher_forunit != "" &&
                  props.ifeedItem.dc_publisher_forunit != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Publicerat för enhet
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.dc_publisher_forunit}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.dc_creator_document != "" &&
                  props.ifeedItem.dc_creator_document != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Upprättad av
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.dc_creator_document}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.dc_contributor_acceptedby != "" &&
                  props.ifeedItem.dc_contributor_acceptedby != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Godkänd av
                      </div>
                      <div className="ifeed-block__info-content">
                        {props.ifeedItem.dc_contributor_acceptedby}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.ifeedItem.dc_date_validfrom != "" &&
                  props.ifeedItem.dc_date_validfrom != null ? (
                    <div className="block__row">
                      <div className="ifeed-block__info-heading">
                        Tillgänglig från
                      </div>
                      <div className="ifeed-block__info-content">
                        {dayjs(props.ifeedItem.dc_date_validfrom).format(
                          "D MMMM YYYY"
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </td>
      {props.includeIfeedDate ? (
        <td>
          <time
            dateTime={dayjs(props.ifeedItem?.createdDate).format("D MMMM YYYY")}
          >
            {dayjs(props.ifeedItem?.createdDate).format("D MMMM YYYY")}
          </time>
        </td>
      ) : (
        <></>
      )}
      {props.includeExpirationDate &&
      props.ifeedItem.validTo != "" &&
      props.ifeedItem.validTo != null ? (
        <td>
          <time
            dateTime={dayjs(props.ifeedItem.validTo_As_Date?.toString()).format(
              "D MMMM YYYY"
            )}
          >
            {dayjs(props.ifeedItem.validTo).format("D MMMM YYYY")}
          </time>
        </td>
      ) : (
        <></>
      )}
      {props.showWarningIcon ? (
        <text>
          <svg
            fill="red"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Dokumentet har gått ut </title>
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
          </svg>
        </text>
      ) : (
        <></>
      )}
    </tr>
  );
};
