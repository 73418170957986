import Select, { ActionMeta } from "react-select";
import { swedishAriaLiveMessages } from "../SearchService/shared/swedishAriaLiveMessages";
import DatePicker, { registerLocale } from "react-datepicker";
import { sv } from 'date-fns/locale/sv';
import { WizardContext } from "./store/wizard.store";
import { useContext, useEffect, useRef, useState } from "react";
import { WizardSearchResult } from "./WizardSearchResult";
import * as signalR from "@microsoft/signalr";
registerLocale('sv', sv)

export const WizardSearchStep: React.VFC<WizardSearchStepProps> = (props) => {
    let { wizardFormData, setWizardFormData } = useContext(WizardContext);
    const [isLoadingLastMinute, setIsLoadingLastMinute] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [skip, setSkip] = useState(0);
    const [errorHeader, setErrorHeader] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    //This is only used for the related clinics feature, we want to keep track if the selected clinics have any related clinics.
    const [selectedClinics, setSelectedClinics] = useState([] as AdditionalClinicInformation[]);
    const ref = useRef<HTMLInputElement>(null);
    const SSR = typeof window === 'undefined';

    //Id 26 is the id for "Hygienist" in T4, we only want to show clinics that have hygienists available.
    //The values need to be local for this component since the values can be different for each treatment type.
    const selectOptions: Option[] = props.additionalClinicInformation.map(x => {
        if (wizardFormData.selectedTreatmentType.id !== 26) {
            return {
                value: x.multiCheckboxItem.checkboxValue,
                label: x.multiCheckboxItem.checkboxText,
            } as Option;
        } else if (x.multiCheckboxItem.isHygienistAvailable) {
            return {
                value: x.multiCheckboxItem.checkboxValue,
                label: x.multiCheckboxItem.checkboxText,
            } as Option;
        }
    });

    const connection = new signalR.HubConnectionBuilder()
        .withUrl("/ftvBookingHub?vgrform=1")
        .build();

    useEffect(() => {
        wizardFormData.isBookingWithCode && getSearchResultForBookingCode();

        return () => {
            connection.stop();
        }
    }, [])

    const selectOnChange = (
        option: readonly Option[],
        actionMeta: ActionMeta<Option>
    ) => {
        const options =
            option.length > 0
                ? (option.map((checkbox) => ({
                    value: checkbox.value,
                    label: checkbox.label,
                })) as Option[])
                : [];

        options.map((checkbox) => {
            const clinic = props.additionalClinicInformation.find(x => x.multiCheckboxItem.checkboxValue == checkbox.value);

            if (clinic &&
                !selectedClinics.find(x => x.multiCheckboxItem.checkboxValue == clinic.multiCheckboxItem.checkboxValue)) {
                setSelectedClinics((prevState) => [...prevState, clinic]);
            }
        });


        setWizardFormData((prevState) => ({ ...prevState, selectedClinics: options }));
    };

    const onChangeDate = (date) => {
        setWizardFormData((prevState) => ({ ...prevState, selectedDate: date }));
    };

    const onClickSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
        setWizardFormData((prevState) => ({ ...prevState, searchResult: [], lastMinuteSearchResult: {} as TimeSlot, showSearchResult: true }));
        if (wizardFormData.isBookingWithCode) {
            getSearchResultForBookingCode();
        } else {
            getSearchResult();
        }
    }

    const getSearchResult = async () => {
        const request = {
            currentPageId: wizardFormData.currentPageId,
            selectedClinics: wizardFormData.selectedClinicsIds && wizardFormData.selectedClinics.map(x => x.value),
            selectedDate: wizardFormData.selectedDate,
            SelectedTreatmentType: wizardFormData.selectedTreatmentType,
            skip: 0
        };

        setSkip(0);

        props.activateLastMinute && setIsLoadingLastMinute(true);
        setIsLoading(true);

        await connection.start().then(async () => {
            props.activateLastMinute && connection.send("getLastMinuteTimes", request);
            connection.send("getTimes", request);
        }).catch((err) => console.error(err));

        props.activateLastMinute && connection.on("lastMinuteTimesReceived", (response: TimeSlot) => {
            if (response) {
                setWizardFormData((prevState) => ({ ...prevState, lastMinuteSearchResult: response }));
            } else if (response === null) {
                setWizardFormData((prevState) => ({ ...prevState, lastMinuteSearchResult: null }));
            }
            setIsLoadingLastMinute(false);
        });

        connection.on("timesReceived", (response: TimeSlotResponse) => {
            if (response && !response.errorMessage && response.timeSlots && response.timeSlots.length > 0) {
                setWizardFormData((prevState) => ({ ...prevState, searchResult: response.timeSlots }));
            } else if (response && response.errorMessage) {
                setErrorHeader(response.errorHeader);
                setErrorMessage(response.errorMessage);
            } else {
                setWizardFormData((prevState) => ({ ...prevState, searchResult: null }))
            }
            setIsLoading(false);
        });
    }

    const getSearchResultForBookingCode = async () => {
        setWizardFormData((prevState) => ({ ...prevState, showSearchResult: true }));

        const request: WizardSearchForBookingCodeRequest = {
            currentPageId: wizardFormData.currentPageId,
            selectedDate: wizardFormData.selectedDate,
            skip: 0,
            bookingCode: wizardFormData.bookingCode,
            personalNumber: wizardFormData.personalNumber
        };

        setSkip(0);

        props.activateLastMinute && setIsLoadingLastMinute(true);
        setIsLoading(true);

        await connection.start().then(async () => {
            connection.send("getTimesForBookingCode?vgrform=1", request);
        }).catch((err) => console.error(err));

        connection.on("timesReceivedForBookingCode", (response: TimeSlotResponse) => {
            if (response && !response.errorMessage && response.timeSlots && response.timeSlots.length > 0) {
                setWizardFormData((prevState) => ({
                    ...prevState,
                    searchResult: response.timeSlots,
                    selectedClinics: [selectOptions.find(x => x.value == response.timeSlots[0].healthcareFacilityId)]
                }));
            } else if (response && response.errorMessage) {
                setErrorHeader(response.errorHeader);
                setErrorMessage(response.errorMessage);
            } else {
                setWizardFormData((prevState) => ({ ...prevState, searchResult: null }))
            }
            setIsLoading(false);
        });
    }

    const onChangeRelatedClinics = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            let combinedClinics = wizardFormData.selectedClinics;

            for (let selectedClinic of wizardFormData.selectedClinics) {
                const selectedClinicInfo = props.additionalClinicInformation.find(x => x.multiCheckboxItem.checkboxValue == selectedClinic.value);

                if (selectedClinicInfo.multiCheckboxItem.relatedClinics) {
                    for (let clinic of selectedClinicInfo.multiCheckboxItem.relatedClinics) {
                        let selectedClinic = props.additionalClinicInformation.find(x => x.multiCheckboxItem.checkboxValue == clinic);
                        if (selectedClinic && !combinedClinics.find(x => x.value == selectedClinic.multiCheckboxItem.checkboxValue)) combinedClinics.push({ value: selectedClinic.multiCheckboxItem.checkboxValue, label: selectedClinic.multiCheckboxItem.checkboxText });
                    }
                }
            }

            setWizardFormData((prevState) => ({
                ...prevState,
                showRelatedClinics: !prevState.showRelatedClinics,
                searchResult: [],
                lastMinuteSearchResult: {} as TimeSlot,
            }));

            getSearchResult();
        } else {
            setWizardFormData((prevState) => ({
                ...prevState,
                showRelatedClinics: !prevState.showRelatedClinics,
                searchResult: [],
                lastMinuteSearchResult: {} as TimeSlot,
            }));

            getSearchResult();
        }
    }

    const checkboxKeyDown = (e: React.KeyboardEvent<HTMLLabelElement>) => {
        if (e.key === " " || e.key === "Spacebar") {
            e.preventDefault();
            ref.current.click();
        }
    };

    return (
        <div className="grid g-col-12">
            <div className="grid g-col-12 ftvwebbooking__header">
                <div className="g-col-12 g-col-md-6">
                    <label htmlFor="clinics">Sök efter klinik</label>
                    {!SSR ? <Select
                        isMulti={true}
                        id={"clinics"}
                        closeMenuOnSelect={false}
                        name={"clinics"}
                        options={selectOptions}
                        value={wizardFormData.selectedClinics}
                        onChange={selectOnChange}
                        placeholder={'Välj klinik...'}
                        defaultValue={selectOptions[0]}
                        ariaLiveMessages={swedishAriaLiveMessages}
                        isDisabled={isLoading || isLoadingLastMinute}
                        className="multi-select"
                        classNames={{
                            control: function (state) {
                                if (state.isDisabled) {
                                    return 'multi-select__control multi-select--loading'
                                }
                                return state.isFocused ? 'multi-select__control multi-select__control--focus' : 'multi-select__control';
                            },
                            menuList: (state) => 'multi-select__menu-list',
                            option: (state) => 'multi-select__option',
                            indicatorsContainer: (state) => 'multi-select__indicators-container',
                            multiValue: (state) => 'multi-select__multi-value',
                            multiValueLabel: (state) => 'multi-select__multi-value-label',
                            multiValueRemove: (state) =>
                                state.isFocused
                                    ? 'multi-select__multi-value-remove multi-select__multi-value-remove--focus'
                                    : 'multi-select__multi-value-remove',
                            clearIndicator: (state) => 'multi-select__clear-indicator',
                            placeholder: (state) => 'multi-select__placeholder'
                        }}
                    /> : null}
                </div>
                <div className="g-col-12 g-col-md-4">
                    <label htmlFor="datepicker">Välj datum</label>
                    <DatePicker
                        id="datepicker"
                        wrapperClassName="vgr-datepicker"
                        selected={wizardFormData.selectedDate}
                        onChange={onChangeDate}
                        shouldCloseOnSelect={false}
                        disabled={isLoading || isLoadingLastMinute}
                        disabledKeyboardNavigation
                        locale="sv"
                        minDate={new Date()}
                        dateFormat="yyy/MM/dd"
                    />
                </div>
                <div className="ftvwebbooking__header-button-container g-col-12 g-col-md-2">
                    <button className="button-primary" type="button" onClick={onClickSearch} disabled={isLoading || isLoadingLastMinute}>Sök tider</button>
                </div>
                {wizardFormData.showSearchResult &&
                    !wizardFormData.isBookingWithCode &&
                    selectedClinics.find(x => x.multiCheckboxItem.relatedClinics && x.multiCheckboxItem.relatedClinics.length > 0) &&
                    <div className="g-col-12 ftvwebbooking__switch-container">
                        <label className="vgr-switch"
                            onKeyDown={checkboxKeyDown}
                            aria-checked={ref.current?.checked}
                        >
                            Visa relaterade kliniker
                            <input
                                ref={ref}
                                disabled={isLoading}
                                type="checkbox"
                                tabIndex={-1}
                                defaultChecked={wizardFormData.showRelatedClinics}
                                name="relatedClinics"
                                onChange={(e) => onChangeRelatedClinics(e)}
                            />
                            <span className="vgr-switch__ui"></span>
                        </label>
                    </div>}
            </div>
            {wizardFormData.showSearchResult && <WizardSearchResult
                infoTextLastMinuteSearchResult={props.infoTextLastMinuteSearchResult}
                infoTextSearchResult={props.infoTextSearchResult}
                lastMinuteResultIcon={props.lastMinuteResultIcon}
                searchResultIcon={props.searchResultIcon}
                isLoading={isLoading}
                isLoadingLastMinute={isLoadingLastMinute}
                setIsLoading={setIsLoading}
                skip={skip}
                setSkip={setSkip}
                activateLastMinute={props.activateLastMinute}
                additionalClinicInformation={props.additionalClinicInformation}
                isBookingWithCode={wizardFormData.isBookingWithCode}
                errorHeader={errorHeader}
                errorMessage={errorMessage}
                setErrorHeader={setErrorHeader}
                setErrorMessage={setErrorMessage}
            />}
        </div>
    );
}