import React, { useRef, useState, useEffect } from 'react';
import { Facet } from '../../../shared.models';
import { selectIsLoading } from '../../../store/shared.reducer';
import { useAppSelector } from '../../../store/store.hooks';
import { ExpandButton } from './ExpandButton';
import { Tree } from './Tree';

interface Props {
  treeId: string;
  maxTopLevelItems: number;
  item: Facet;
  checkboxInteracted: (item: Facet) => void;
}
export const Item: React.VFC<Props> = ({ treeId, maxTopLevelItems, item, checkboxInteracted }) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [isExpanded, setIsExpanded] = useState(item._initExpanded);
  const isLoading = useAppSelector(selectIsLoading);
  const hasChildren = item.children?.length > 0;
  const ariaChecked = item._indeterminate ? 'mixed' : item.selected ? 'true' : 'false';
  
  // Whenever items indeterminate state changes update the checkbox in DOM with correct indeterminate value 
  useEffect(() => {
    checkboxRef.current.indeterminate = !!item._indeterminate;
  }, [item._indeterminate])

  const expandButtonClick = () => setIsExpanded(!isExpanded);

  const checkboxClick = () => {
    // Will expand the items children when trying to check the checkbox and its not already expanded
    if (hasChildren && !item.selected && !isExpanded) expandButtonClick();
    checkboxInteracted(item);
  };

  const checkboxKeyDown = (e: React.KeyboardEvent<HTMLLabelElement>) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      e.preventDefault();
      checkboxClick();
    }
  };

  return (
    <li key={item.id} aria-expanded={isExpanded} className="vgr-checkbox-tree__item">
      <div className="vgr-checkbox-tree__head">
        <span className="vgr-checkbox-tree__item-tail"></span>
        <label
          onKeyDown={checkboxKeyDown}
          className="vgr-checkbox-tree__checkbox"
          tabIndex={0}
          role="checkbox"
          aria-checked={ariaChecked}
        >
          <span className="sr-only">
            {item.displayName}, {item.count} dokument
          </span>
          <input
            onChange={checkboxClick}
            ref={checkboxRef}
            type="checkbox"
            disabled={isLoading}
            checked={item.selected}
            id={item.id}
            value={item.query}
            tabIndex={-1}
          />
          <span className="vgr-checkbox-tree__icon vgr-checkbox-tree__icon--checked-true">
            <svg
              className="vgr-icon vgr-icon--"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              viewBox="0 0 24 24"
              xmlSpace="preserve"
            >
              <rect fill="#006298" width="24" height="24" />
              <g>
                <path
                  style={{ transform: 'translate(10%, 10%)' }}
                  fill="#FFFFFF"
                  d="M7.9,15.1C7.9,15.1,7.9,15.1,7.9,15.1c-0.6,0-1-0.3-1.3-0.8l-1.9-3c-0.2-0.4-0.1-0.8,0.2-1   c0.3-0.2,0.8-0.1,1,0.2l1.9,3L14,4.9c0.2-0.3,0.7-0.4,1-0.2c0.3,0.2,0.4,0.7,0.2,1l-6,8.6C8.9,14.8,8.4,15.1,7.9,15.1z"
                />
              </g>
            </svg>
          </span>

          <span className="vgr-checkbox-tree__icon vgr-checkbox-tree__icon--checked-mixed">
            <svg
              fill="#006298"
              className="vgr-icon vgr-icon--"
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M0 0 H24 V24 H0 z M2 2 L2 2 v1 L3 2 z M5 2 L2 5 v2 L7 2 z M9 2 L2 9 v2 L11 2 z M13 2 L2 13 v2 L15 2 z M17 2 L2 17 v2 L19 2 z M21 2 L2 21 v1 h1 L22 3 v-1 z M22 5 L5 22 h2 L22 7 z M22 9 L9 22 h2 L22 11 z M22 13 L13 22 h2 L22 15 z M22 17 L17 22 h2 L22 19 z M22 21 L21 22 h1 z"
              ></path>
            </svg>
          </span>

          <span className="vgr-checkbox-tree__icon vgr-checkbox-tree__icon--checked-false">
            <svg
              fill="#006298"
              className="vgr-icon vgr-icon--"
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M0 0h24v24h-24z M2 2h20v20h-20z" fillRule="evenodd"></path>
            </svg>
          </span>
        </label>
        <span className="vgr-checkbox-tree__label">
          {item.displayName} <span className="vgr-searchservice__facet-count">({item.count})</span>
        </span>
        {hasChildren ? <ExpandButton isExpanded={isExpanded} clickCallback={expandButtonClick} /> : null}
      </div>
      {hasChildren ? (
        <Tree
          maxTopLevelItems={maxTopLevelItems}
          treeId={treeId}
          items={item.children}
          checkboxInteracted={checkboxInteracted}
        />
      ) : null}
    </li>
  );
};
