import React, { useState, useEffect } from 'react';
import { selectIsLoading, selectSearchResult } from '../store/shared.reducer';
import { useAppSelector } from '../store/store.hooks';

export const CopySearchSettings: React.VFC = () => {
  const [urlToCopy, setUrlToCopy] = useState('');
  const searchResult = useAppSelector(selectSearchResult);
  const isLoading = useAppSelector(selectIsLoading);
  const [showCopy, setShowCopy] = useState(false);

  const copySearchSettings = () => {
    const url = new URLSearchParams(window.location.href);
    url.delete('q');
    setUrlToCopy(decodeURIComponent(url.toString()));
  };

  useEffect(() => copySearchSettings(), [searchResult.query.parameters]);

  return (
    <div>
      <button
        type="button"
        onClick={() => setShowCopy(!showCopy)}
        className="vgr-searchservice__widgetbtn vgr-searchservice__linkbutton"
      >
        Kopiera sökinställningar
      </button>
      {showCopy && (
        <div className="vgr-searchservice__copyurl">
          <div>Kopiera adressen med CTRL + C (Windows) eller ⌘ + C (Mac)</div>
          <input type="text" disabled={isLoading} readOnly={true} value={urlToCopy} />
        </div>
      )}
    </div>
  );
};
