import React, { ReactHTMLElement } from "react";
import dayjs from "dayjs";
import { ResultType, StyrandeDokument } from "./models";
import { useAppDispatch, useAppSelector } from "../shared/store/store.hooks";
import { selectActiveFilters } from "../shared/store/shared.reducer";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface Props {
  item: StyrandeDokument;
}
export const SearchResultItem: React.VFC<Props> = ({ item }) => {
  const activeFilters = useAppSelector(selectActiveFilters);
  const dispatch = useAppDispatch();

  /*********************************
   * Functions
   *********************************/
  function getUnitString(units: string[]) {
    if (!units || units.length === 0) return "";

    // Default the displayed unit to the first one in the list
    let displayUnit = units[0];

    // If we can find a matching unit in activeFilters, set that one as displayUnit instead
    units.forEach((unit) => {
      const match = activeFilters.find((x) => x.displayName === unit);
      if (match) displayUnit = unit;
    });

    return units.length === 1
      ? displayUnit
      : `${displayUnit} (+${units.length - 1} till)`;
  }

  // TODO: When new icons arrive, change icon for corresponding type
  const getIcon = () => {
    switch (item.styrandedokumentResultType) {
      case ResultType.Lokalt:
        return stampIcon;
      case ResultType.Övergripande:
        return stampIcon;
      case ResultType.Regionövergripande:
        return stampIcon;
      default:
        return stampIcon;
    }
  };

  async function onSearchResultClick() {
    let urlRightPart = location.hostname;
    if (!urlRightPart.endsWith("/")) {
      urlRightPart += "/";
    }
    await fetch(location.pathname + "TrackClickUrlAsync", {
      method: "POST",
      body: JSON.stringify({ clickUrl: item.clickUrl }),
    });
  }

  /*********************************
   * Render
   *********************************/
  return (
    <article className="vgr-searchservice-item">
      <header>
        <div className="vgr-searchservice-item__heading-wrapper">
          <div className="vgr-searchservice-item__contain">
            <a
              className="vgr-searchservice-item__header-link"
              href={item.url}
              onClick={onSearchResultClick}
            >
              {getIcon()}
              <h3 className="vgr-searchservice-item__heading">{item.title}</h3>
            </a>
            <h4 className="vgr-searchservice-item__sub-heading">
              {dayjs(item.validFrom).format("D MMMM YYYY")} •{" "}
              {getUnitString(item.forUnit)} • {getUnitString(item.validityArea)}{" "}
              • {item.objectType}
            </h4>
          </div>

          <div className="vgr-searchservice-item__badgewrapper">
            {item.isNewDocument && (
              <div className="vgr-badge new">Nyligen upprättat</div>
            )}
            <div className="vgr-badge validto">
              Giltig till: {dayjs(item.validTo).format("D MMMM YYYY")}
            </div>
          </div>
        </div>
      </header>

      <div className="vgr-searchservice-item__body">
        <details>
          <summary>
            <span>
              <span className="title-show">Visa</span>
              <span className="title-hide">Dölj</span> mer uppgifter
              <span className="sr-only">om dokumentet {item.title}</span>
            </span>{" "}
            {chevron}
          </summary>
          <div>
            <div className="vgr-searchservice-item__bodymeta vgr-searchservice-item__datawrapper">
              {item.validFrom && (
                <div>
                  <strong>Upprättat (datum)</strong>
                  <div>{dayjs(item.validFrom).format("D MMMM YYYY")}</div>
                </div>
              )}
              {item.validTo && (
                <div>
                  <strong>Giltigt t.o.m.</strong>
                  <div>{dayjs(item.validTo).format("D MMMM YYYY")}</div>
                </div>
              )}
              {item.objectType && (
                <div>
                  <strong>Handlingstyp</strong>
                  <div>{item.objectType}</div>
                </div>
              )}
              {item.validityArea && (
                <div>
                  <strong>Giltighetsområde</strong>
                  {item.validityArea?.map((va) => {
                    return <div key={item.id + va}>{va}</div>;
                  })}
                </div>
              )}
            </div>

            {item.publishedForUnitWithHierarchy && (
              <div className="vgr-searchservice-item__datawrapper">
                <strong>Upprättat för (enhet)</strong>
                <ul>
                  {item.publishedForUnitWithHierarchy?.map((pfu) => {
                    return <li key={item.id + pfu}>{pfu}</li>;
                  })}
                </ul>
              </div>
            )}

            {item.approvedBy && (
              <div className="vgr-searchservice-item__datawrapper">
                <strong>Godkänd av</strong>
                {item.approvedBy?.map((ab) => {
                  return <div key={item.id + ab}>{ab}</div>;
                })}
              </div>
            )}
            {item.contentResponsible && (
              <div className="vgr-searchservice-item__datawrapper">
                <strong>Innehållsansvarig</strong>
                {item.contentResponsible?.map((cr) => {
                  return <div key={item.id + cr}>{cr}</div>;
                })}
              </div>
            )}

            {item.subjectClassification && (
              <div className="vgr-searchservice-item__datawrapper">
                <strong>Regional ämnesindelning</strong>
                {item.subjectClassification?.map((sc) => {
                  return <div key={item.id + sc}>{sc}</div>;
                })}
              </div>
            )}

            <details>
              <summary>
                <span>Mer information om dokumentet</span> {chevron}
              </summary>
              <div className="vgr-searchservice-item__nested-details-body">
                {item.tag && (
                  <div className="vgr-searchservice-item__datawrapper">
                    <strong>Företagsnyckelord</strong>
                    {item.tag?.map((tag, index) => {
                      return <div key={item.id + tag + index}>{tag}</div>;
                    })}
                  </div>
                )}
                {item.sweMeSHCode && (
                  <div className="vgr-searchservice-item__datawrapper">
                    <strong>Swemesh nyckelord</strong>
                    {item.sweMeSHCode?.map((swemeshCode) => {
                      return (
                        <div key={item.id + swemeshCode}>{swemeshCode}</div>
                      );
                    })}
                  </div>
                )}
                {(item.subjectLocalClassification ||
                  item.hsaVerksamhetsKod) && (
                  <div className="vgr-searchservice-item__bodymeta vgr-searchservice-item__datawrapper">
                    {item.subjectLocalClassification && (
                      <div>
                        <strong>Egen ämnesindelning</strong>
                        {item.subjectLocalClassification?.map((slc, i) => {
                          return <div key={item.id + slc + i}>{slc}</div>;
                        })}
                      </div>
                    )}
                    {item.hsaVerksamhetsKod && (
                      <div>
                        <strong>Verksamhetskod enligt HSA</strong>
                        {item.hsaVerksamhetsKod?.map((hsaVerksamKod) => {
                          return (
                            <div key={item.id + hsaVerksamKod}>
                              {hsaVerksamKod}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
                {item.producer && (
                  <div className="vgr-searchservice-item__datawrapper">
                    <strong>Myndighet</strong>
                    <div>{item.producer}</div>
                  </div>
                )}

                {item.classificationName && (
                  <div className="vgr-searchservice-item__datawrapper">
                    <strong>Klassificeringsstruktur (process)</strong>
                    <div>
                      {item.classificationName} {item.classificationCode}
                    </div>
                  </div>
                )}

                {(item.sourceId || item.sourceVersion) && (
                  <div className="vgr-searchservice-item__bodymeta vgr-searchservice-item__datawrapper">
                    {item.sourceId && (
                      <div>
                        <strong>DokumentId källa</strong>
                        <div>{item.sourceId}</div>
                      </div>
                    )}

                    {item.sourceVersion && (
                      <div className="vgr-searchservice-item__datawrapper">
                        <strong>Version</strong>
                        <div>{item.sourceVersion}</div>
                      </div>
                    )}
                  </div>
                )}

                {item.siteInfoName && (
                  <div className="vgr-searchservice-item__datawrapper">
                    <strong>Ursprunglig samarbetsyta</strong>
                    {item.siteInfoName?.map((sin) => {
                      return <div key={item.id + sin}>{sin}</div>;
                    })}
                  </div>
                )}

                {item.availableTo && (
                  <div className="vgr-searchservice-item__datawrapper">
                    <strong>Tillgänglig till</strong>
                    <div>{dayjs(item.availableTo).format("D MMMM YYYY")}</div>
                  </div>
                )}
              </div>
            </details>
          </div>
        </details>
        {item.description && (
          <div
            className="vgr-searchservice-item__description"
            dangerouslySetInnerHTML={{ __html: item.description }}
          ></div>
        )}
      </div>
    </article>
  );
};

const chevron = (
  <svg aria-hidden={true} style={{ width: "16px", height: "16px" }}>
    <use xlinkHref="#streamline-arrow"></use>
  </svg>
);

const stampIcon = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1898_897)">
      <path
        d="M15.9006 23.998C15.4827 23.998 15.1435 23.662 15.1435 23.248C15.1435 22.834 15.4827 22.498 15.9006 22.498H22.487C22.6102 22.498 22.7112 22.396 22.7102 22.272V7.49805H18.4246C17.4503 7.49805 16.6578 6.71305 16.6578 5.74805V1.49805H7.79485C7.67168 1.49905 7.57174 1.60005 7.57174 1.72405V6.00005C7.57174 6.41405 7.23252 6.75005 6.81456 6.75005C6.3966 6.75005 6.05739 6.41405 6.05739 6.00005V1.72705C6.05537 1.26905 6.23507 0.836047 6.56318 0.508047C6.89129 0.181047 7.32742 4.68751e-05 7.79182 -0.00195312H17.5876C17.7875 -0.00195312 17.9844 0.0790469 18.1247 0.220047L24.0044 6.09005C24.1458 6.23105 24.2245 6.41905 24.2245 6.61805V6.67505C24.2275 6.70105 24.2295 6.72405 24.2295 6.74805C24.2295 6.77305 24.2275 6.79705 24.2245 6.82105V22.27C24.2265 23.219 23.4492 23.994 22.4901 23.998H22.4891H15.9006ZM18.1722 5.74805C18.1722 5.88605 18.2852 5.99805 18.4246 5.99805H21.7793L18.1722 2.39705V5.74805Z"
        fill="#006298"
      />
      <path
        d="M12.8719 11.248C12.454 11.248 12.1147 10.912 12.1147 10.498C12.1147 10.084 12.454 9.74805 12.8719 9.74805H21.003C21.4209 9.74805 21.7601 10.084 21.7601 10.498C21.7601 10.912 21.4209 11.248 21.003 11.248H12.8719Z"
        fill="#006298"
      />
      <path
        d="M18.1722 20.248C17.7542 20.248 17.415 19.912 17.415 19.498C17.415 19.084 17.7542 18.748 18.1722 18.748H21.003C21.4209 18.748 21.7601 19.084 21.7601 19.498C21.7601 19.912 21.4209 20.248 21.003 20.248H18.1722Z"
        fill="#006298"
      />
      <path
        d="M14.3863 15.748C13.9683 15.748 13.6291 15.412 13.6291 14.998C13.6291 14.584 13.9683 14.248 14.3863 14.248H21.0029C21.4209 14.248 21.7601 14.584 21.7601 14.998C21.7601 15.412 21.4209 15.748 21.0029 15.748H14.3863Z"
        fill="#006298"
      />
      <path
        d="M1.51435 23.9982C1.16605 23.9982 0.864187 23.7652 0.779384 23.4302L0.0222104 20.4302C0.0191817 20.4182 0.016153 20.4052 0.0141339 20.3922C0.0111052 20.3732 0.00807651 20.3572 0.00706695 20.3402C0.00403826 20.3192 0.00201913 20.3002 0.00100956 20.2822C0.00100956 20.2712 0 20.2592 0 20.2482C0 18.1802 1.69809 16.4982 3.78587 16.4982H4.54304V14.9842C4.13618 14.6812 3.7899 14.2952 3.53549 13.8592C3.0297 12.9922 2.89543 11.9812 3.15691 11.0142C3.41838 10.0462 4.04532 9.23815 4.92062 8.73715C5.49708 8.40815 6.14926 8.23315 6.8085 8.23315C7.13964 8.23315 7.47078 8.27615 7.79384 8.36215C8.77109 8.62115 9.58682 9.24215 10.0926 10.1092C10.5984 10.9762 10.7327 11.9872 10.4712 12.9542C10.2511 13.7692 9.76249 14.4832 9.08608 14.9852V16.4982H9.84325C11.931 16.4982 13.6291 18.1802 13.6291 20.2482C13.6291 20.2602 13.6281 20.2732 13.6271 20.2862C13.6261 20.3052 13.6241 20.3222 13.621 20.3392C13.619 20.3582 13.617 20.3762 13.613 20.3942C13.612 20.4002 13.6109 20.4062 13.6099 20.4122L12.8487 23.4302C12.7639 23.7642 12.4621 23.9982 12.1138 23.9982H1.51435ZM11.5232 22.4982L11.9018 20.9982H1.72736L2.10595 22.4982H11.5232ZM11.9845 19.4982C11.6685 18.6192 10.8145 17.9982 9.84325 17.9982H8.32891C7.91095 17.9982 7.57173 17.6622 7.57173 17.2482V14.5822C7.57173 14.3152 7.71711 14.0662 7.95032 13.9322C8.47529 13.6322 8.85186 13.1472 9.00834 12.5662C9.16583 11.9852 9.08507 11.3792 8.78119 10.8592C8.37736 10.1652 7.62221 9.73415 6.80951 9.73415C6.41376 9.73415 6.02306 9.83815 5.6788 10.0352C5.15282 10.3362 4.77726 10.8212 4.62078 11.4012C4.46328 11.9822 4.54405 12.5882 4.84793 13.1082C5.04681 13.4502 5.33454 13.7342 5.6788 13.9322C5.91201 14.0662 6.05739 14.3152 6.05739 14.5822V17.2482C6.05739 17.6622 5.71817 17.9982 5.30021 17.9982H3.78587C2.81467 17.9982 1.96057 18.6192 1.64458 19.4982H11.9845Z"
        fill="#006298"
      />
    </g>
    <defs>
      <clipPath id="clip0_1898_897">
        <rect width="24.2295" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
