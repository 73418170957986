import React from 'react';
import { Facet, JobSearchItem, SearchResponse } from '../models';
import { Dropdown } from './Dropdown';
import { MultiSelect } from './MultiSelect';

interface Props {
  searchResult: SearchResponse<JobSearchItem>;
  isLoading: boolean;
  defaultOrganization: string;
  isPost: boolean;
  workTitleFacets: Facet[];
  organisationFacets: Facet[];
  hireTypeFacets: Facet[];
  cityFacets: Facet[];
  nameSelectOptionRef: React.RefObject<HTMLSelectElement>;
  departmentSelectOptionRef: React.RefObject<HTMLSelectElement>;
  workFormSelectOptionRef: React.RefObject<HTMLSelectElement>;
}

export const Filtering: React.VFC<Props> = ({
  searchResult,
  isLoading,
  defaultOrganization,
  isPost,
  organisationFacets,
  workTitleFacets,
  hireTypeFacets,
  cityFacets,
  nameSelectOptionRef,
  departmentSelectOptionRef,
  workFormSelectOptionRef,
}) => {
  /*********************************
   * Variables
   *********************************/
  let departmentFacets: Facet[] = [];
  let nameFacets: Facet[] = [];
  let municipalityFacets: Facet[] = [];
  let employmenFormFacets: Facet[] = [];

  /*********************************
   * Functions
   *********************************/
  searchResult.facets.forEach((facet) => {
    if (facet.id == 'municipality_facet') {
      facet.filters.forEach((item) => {
        municipalityFacets.push(item);
      });
    }

    if (facet.id == 'name_facet') {
      facet.filters.forEach((item) => {
        nameFacets.push(item);
      });
    }
    if (facet.id == 'department_facet') {
      facet.filters.forEach((item) => {
        departmentFacets.push(item);
      });
    }
    if (facet.id == 'employment_form') {
      facet.filters.forEach((item) => {
        employmenFormFacets.push(item);
      });
    }
  });

  departmentFacets = departmentFacets.sort((a, b) => a.displayName.localeCompare(b.displayName, 'sv'));
  nameFacets = nameFacets.sort((a, b) => a.displayName.localeCompare(b.displayName, 'sv'));
  municipalityFacets = municipalityFacets.sort((a, b) => a.displayName.localeCompare(b.displayName, 'sv'));
  employmenFormFacets = employmenFormFacets.sort((a, b) => a.displayName.localeCompare(b.displayName, 'sv'));

  /*********************************
   * Render
   *********************************/
  return (
    <fieldset className="grid" id="filter">
      <legend className={!isPost ? 'visually-hidden' : ''}>Filtrera sökning</legend>
      <div className="g-col-md-3 g-col-12">
        <label className="visually-hidden" htmlFor="select-1">
          Yrkesområde
        </label>
        <Dropdown
          defaultText={'Yrkesområde'}
          defaultSelectedOptionValue=""
          itemsId="select-1"
          itemsName="name_facet"
          isLoading={isLoading}
          isPost={isPost}
          facets={!isPost ? workTitleFacets : nameFacets}
          selectOptionRef={nameSelectOptionRef}
        />
      </div>
      <div className="g-col-md-3 g-col-12">
        <label className="visually-hidden" htmlFor="select-2">
          Förvaltning
        </label>
        <Dropdown
          defaultText={'Förvaltning'}
          defaultSelectedOptionValue={defaultOrganization}
          itemsId="select-2"
          itemsName="department_facet"
          isLoading={isLoading}
          isPost={isPost}
          facets={!isPost ? organisationFacets : departmentFacets}
          selectOptionRef={departmentSelectOptionRef}
        />
      </div>
      <div className="g-col-md-3 g-col-12">
        <label className="visually-hidden" htmlFor="select-3">
          Anställningsform
        </label>
        <Dropdown
          defaultText="Anställningsform"
          defaultSelectedOptionValue=""
          itemsId="select-3"
          itemsName="employment_form"
          isLoading={isLoading}
          facets={!isPost ? hireTypeFacets : employmenFormFacets}
          isPost={isPost}
          selectOptionRef={workFormSelectOptionRef}
        />
      </div>
      <div className="g-col-md-3 g-col-12">
        <label className="visually-hidden" htmlFor="select-4">
          Kommun
        </label>
        <MultiSelect
          isPost={isPost}
          itemsId="select-4"
          itemsName="municipality_facet"
          facets={!isPost ? cityFacets : municipalityFacets}
          isLoading={isLoading}
        />
      </div>
    </fieldset>
  );
};
