import React from "react";
import { StyrandeDokument } from "../../StyrandeDokument/models";
import { useSearchEngine } from "../hooks";
import { SearchResponse } from "../shared.models";
import {
  clearActiveFilters,
  selectSearchResult,
} from "../store/shared.reducer";
import { useAppDispatch, useAppSelector } from "../store/store.hooks";

export const NoHits: React.VFC = () => {
  const dispatch = useAppDispatch();
  const pathName =
    typeof window !== "undefined" ? window.location.pathname : ""; // En tillfällig fix. Sökningen kommer inte att fungera utan javascript, eftersom vi behöver sökvägen till sidan.
  const [doSearch] = useSearchEngine(pathName + "FilterStyrandeDokument");

  const searchResult = useAppSelector(
    selectSearchResult
  ) as SearchResponse<StyrandeDokument>;
  const includesFilters =
    useAppSelector(selectSearchResult).includesFilters.filters;
  const hasSelectedIncludesFilters = !!includesFilters.filter((x) => x.selected)
    .length;

  const resetQuery = new URLSearchParams(searchResult.query.resetQuery);
  resetQuery.delete("q");
  resetQuery.delete("inkludera");

  function doResetQuery(e: React.MouseEvent) {
    e.preventDefault();
    dispatch(clearActiveFilters());
    doSearch(resetQuery);
    (document.getElementById("main-search-input") as HTMLInputElement).value =
      "";
  }

  return (
    <div className="">
      {hasSelectedIncludesFilters && (
        <div className="vgr-searchservice-item__nohits">
          <h2 className="vgr-searchservice-item__heading vgr-searchservice-item__header-link">
            {noHitIcon} Inga matchningar för &quot;
            {searchResult.query.queryString}&quot; kunde hittas
          </h2>
          <strong>Söktips:</strong>
          <ul>
            <li>
              Kontrollera att sökorden är rättstavade, eller testa synonymer
            </li>
            <li>Kontrollera dina filter</li>
            <li>Du kan testa att söka utan sökord och enbart använda filter</li>
            <li>
              Se om det finns relevanta träffar bland exempel Övergripande
              dokument eller Vårdhandboken (under Inkludera dokument)
            </li>
            <li>
              Du kan testa att{" "}
              <a
                href={"?" + resetQuery.toString()}
                onClick={(e) => doResetQuery(e)}
              >
                nollställa din sökning
              </a>{" "}
              och börja om från början
            </li>
            <li>
              Se om det du söker finns i{" "}
              <a href="https://hittaiarkivet.vgregion.se">
                Hitta i arkivet istället
              </a>
            </li>
          </ul>
          <p>
            Vi arbetar ständigt med att förbättra sökfunktionen, skicka
            förbättringsförslag till{" "}
            <a href="mailto:webb@vgregion.se">webb@vgregion.se</a>
          </p>
        </div>
      )}
      {!hasSelectedIncludesFilters && (
        <div className="vgr-searchservice-item__nohits">
          <h2 className="vgr-searchservice-item__heading vgr-searchservice-item__header-link">
            {noHitIcon} Inga dokument valda
          </h2>
          <p>
            Kontrollera att minst ett av alternativen under{" "}
            <strong>&quot;Inkludera dokument&quot;</strong> är påslaget.
          </p>
          <p>
            Vi arbetar ständigt med att förbättra sökfunktionen, skicka
            förbättringsförslag till{" "}
            <a href="mailto:webb@vgregion.se">webb@vgregion.se</a>
          </p>
        </div>
      )}
    </div>
  );
};

const noHitIcon = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1733_94710)">
      <path
        d="M6.5625 30C6.4175 30 6.2725 29.9662 6.1425 29.9012C5.82375 29.7412 5.625 29.42 5.625 29.0625V24.375H2.8125C1.26125 24.375 0 23.1138 0 21.5625V2.8125C0 1.26125 1.26125 0 2.8125 0H27.1875C28.7388 0 30 1.26125 30 2.8125V21.5625C30 23.1138 28.7388 24.375 27.1875 24.375H14.375L7.125 29.8125C6.96375 29.9338 6.76375 30 6.5625 30ZM2.8125 1.875C2.295 1.875 1.875 2.295 1.875 2.8125V21.5625C1.875 22.08 2.295 22.5 2.8125 22.5H6.5625C7.08 22.5 7.5 22.92 7.5 23.4375V27.1875L13.5 22.6875C13.6613 22.5662 13.8612 22.5 14.0625 22.5H27.1875C27.705 22.5 28.125 22.08 28.125 21.5625V2.8125C28.125 2.295 27.705 1.875 27.1875 1.875H2.8125Z"
        fill="#757575"
      />
      <path
        d="M15.9375 14.9988C15.42 14.9988 15 14.5788 15 14.0613V13.74C15 12.55 15.7537 11.485 16.875 11.0887C17.9963 10.6925 18.75 9.62625 18.75 8.4375C18.75 7.68625 18.4575 6.98 17.9262 6.44875C17.395 5.9175 16.6887 5.625 15.9375 5.625C15.1862 5.625 14.48 5.9175 13.9488 6.44875C13.4175 6.97875 13.125 7.685 13.125 8.43625C13.125 8.9525 12.705 9.37375 12.1875 9.37375C11.67 9.37375 11.25 8.9525 11.25 8.43625C11.25 7.18375 11.7387 6.0075 12.6237 5.1225C13.5087 4.2375 14.6862 3.75 15.9375 3.75C17.19 3.75 18.3675 4.2375 19.2525 5.12375C20.1375 6.00875 20.625 7.18625 20.625 8.4375C20.625 10.42 19.3687 12.195 17.5 12.8563C17.1262 12.9888 16.875 13.3438 16.875 13.74V14.0613C16.875 14.5788 16.455 14.9988 15.9375 14.9988Z"
        fill="#757575"
      />
      <path
        d="M15.9375 19.6875C16.7142 19.6875 17.3438 19.0579 17.3438 18.2812C17.3438 17.5046 16.7142 16.875 15.9375 16.875C15.1608 16.875 14.5312 17.5046 14.5312 18.2812C14.5312 19.0579 15.1608 19.6875 15.9375 19.6875Z"
        fill="#757575"
      />
    </g>
    <defs>
      <clipPath id="clip0_1733_94710">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
