export const validateWizardFormData = (formData: WizardFormData, id?: string): Record<string, string> => {
    const errors: Record<string, string> = { ...formData.validationErrors };

    const personalNumberRegEx = /^(\d{6}|\d{8})[-|(\s)]{0,1}\d{4}$/;

    //First validate that the field is not empty
    if (!formData[id] && id) errors[id] = "Fältet är obligatoriskt";
    //Then validate the individual conditions
    else if (errors[id]) delete errors[id];
    else if (id === "personalNumber" && !personalNumberRegEx.test(formData[id])) errors[id] = "Fältet måste innehålla ett giltigt personnummer";
    else if (!id) {
        if (!formData["getInformationFromSkatteverket"]) errors["getInformationFromSkatteverket"] = "Det är obligatoriskt att hämta personuppgifter från Skatteverket. Om du inte vill det, vänligen kontakta kliniken på telefon för att boka tid.";
        else delete errors["getInformationFromSkatteverket"];
    }

    return errors;
}